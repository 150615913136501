import { setOrderDeliveryPoint, setSelectedCity } from '../../../actions';
import { identityPayload, createReducer, forAction } from '../../../utils';

const initialState = null;

export default createReducer(
  [
    forAction(setOrderDeliveryPoint, identityPayload),
  ],
  initialState,
  [setSelectedCity],
);
