import { setActiveModalAction, setQueryCity } from '../../actions';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = '';

export default createReducer(
  [forAction(setQueryCity, identityPayload)],
  initialState,
  [setActiveModalAction],
);
