import { changeUserCode, logoutAction } from '../../actions';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = '';

export default createReducer(
  [forAction(changeUserCode, identityPayload)],
  initialState,
  [logoutAction],
);
