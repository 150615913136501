import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import cn from 'classnames';
import { numberDevider } from '../../../utils';
import { DATE_FORMAT, LONG_DASH, ORDER_VALUE_MAPS } from '../../../constants';

export function OrderListRow({ order, onClick }) {
  return (
    <tr onClick={onClick(order.id)} className="cursor-pointer custom-tr-hover">
      <td>
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column ms-4">
            <div className="text-dark fw-bolder text-hover-primary mb-1 fs-6">{order.id}</div>
          </div>
        </div>
      </td>
      <td>
        <div className="text-dark d-block mb-1 fs-6">
          {dayjs(order.created_at).format(DATE_FORMAT)}
        </div>
      </td>
      <td>
        <div className="text-dark d-block mb-1 fs-6">
          {ORDER_VALUE_MAPS.deliveryMethod[order.deliveryMethod]}
        </div>
      </td>
      <td>
        <div className="text-dark d-block mb-1 fs-6">{`${numberDevider(order.totalPrice)} ₸`}</div>
      </td>
      <td>
        {order.cashback && order.cashback.level1 ? (
          <div className="badge badge-secondary fs-7 fw-bold">{`${order.cashback.level1} Б.`}</div>
        ) : LONG_DASH}
      </td>
      <td>
        <span className={cn('badge fs-7 fw-bold', ORDER_VALUE_MAPS.status[order.status].style)}>
          {ORDER_VALUE_MAPS.status[order.status].title}
        </span>
      </td>
      <td className="text-end">
        <div className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
          <span className="svg-icon svg-icon-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path opacity="0.3" d="M2 21V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H3C2.4 22 2 21.6 2 21Z" fill="currentColor" />
              <path d="M2 10V3C2 2.4 2.4 2 3 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H3C2.4 11 2 10.6 2 10Z" fill="currentColor" />
            </svg>
          </span>
        </div>
      </td>
    </tr>
  );
}

OrderListRow.propTypes = {
  order: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
