import React from 'react';
import PropTypes from 'prop-types';
import { Card } from './card';
import { CreateWokCard } from '../components';

export function GoodsList({
  goods, title, count, isWok,
}) {
  const emptyResult = (
    <div className="fw-bold">
      <div className="text-gray-700 fs-3 mb-2">Товаров нет</div>
    </div>
  );

  return (
    <>
      <div className="toolbar d-flex flex-stack flex-wrap pb-5 pb-lg-5 border-bottom">
        <div className="page-title d-flex flex-column py-1">
          <h1 className="d-flex align-items-center my-1">
            <span className="text-dark fw-bolder fs-1">{title}</span>
            <small className="text-muted fs-6 fw-bold ms-1">
              {`(${count})`}
            </small>
          </h1>
        </div>
      </div>
      <div key={title} className="post abs">
        <div className="row g-5 g-xl-8">
          { isWok && (
          <div aria-hidden="true" className="col-xl-4">
            <CreateWokCard />
          </div>
          ) }
          {goods.length ? goods.map((id) => <Card key={id} id={id} />) : emptyResult}
        </div>
      </div>

    </>
  );
}

GoodsList.propTypes = {
  goods: PropTypes.arrayOf(PropTypes.shape).isRequired,
  count: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isWok: PropTypes.bool.isRequired,
};
