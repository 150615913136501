import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import reducer from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewareEnhancer = applyMiddleware(sagaMiddleware);
const composeEnhancers = process.env.REACT_APP_REDUXTOOL === 'dev' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

export const store = createStore(
  reducer,
  composeEnhancers(middlewareEnhancer),
);

export const persistor = persistStore(store);

sagaMiddleware.run(sagas);
