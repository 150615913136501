import React from 'react';
import { Link } from 'react-router-dom';

export function FranchiseBanner() {
  return (
    <div className="card mt-10" style={{ background: 'linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)' }}>
      <div className="card-body p-5">
        <div className="row align-items-center h-100 justify-content-between">
          <div className="col-12">
            <div className="text-white mb-6 text-center">
              <span className="fs-2qx fw-bolder">Франшиза</span>
            </div>
            <span className="fw-bold text-white fs-6 mb-8 d-block text-center">Предлагаем открыть собственный магазин SUSHIBOX в вашем городе на условиях франчайзи</span>
            <div className="d-flex flex-column">
              <Link to="/franchise" className="btn btn-sm btn-white btn-color-gray-800">Подробнее</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
