import { createAction } from 'redux-actions';
import { makeActionType } from '../utils';

const namespaceGoods = 'GOODS';
const namespaceCategory = 'CATEGORIES';

export const fetchGoods = createAction(makeActionType(namespaceGoods, ['FETCH']));

export const setGoods = createAction(makeActionType(namespaceGoods, ['SET']));

export const addToFavoriteGoods = createAction(makeActionType(namespaceGoods, ['UPDATE', 'FAVORITE']));

export const setCategories = createAction(makeActionType(namespaceCategory, ['SET']));

export const setEntities = createAction(makeActionType('ENTITIES', ['SET']));
