import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { setActiveModalAction } from '../actions';

export function CreateWokCard({ disableScretch }) {
  const dispatch = useDispatch();
  const onSubmit = () => {
    dispatch(setActiveModalAction({ field: 'wok', value: true }));
  };

  return (
    <div className={cn('card bg-light', { 'card-xl-stretch': !disableScretch })}>
      <div
        style={{ backgroundImage: 'url(/assets/media/bgw.jpg)' }}
        className="rounded h-350px justify-content-between p-10 card-body d-flex flex-column bgi-no-repeat bgi-size-cover bgi-position-center"
      >
        <div>
          <h1 style={{ backgroundColor: '#00000070' }} className="fw-bold p-3 mt-10 rounded text-white text-center">
            Собери свой
            <br />
            <span className="fw-boldest">WOK</span>
          </h1>
        </div>
        <div className="text-center">
          <button style={{ backgroundColor: '#ed1b23c2' }} onClick={onSubmit} type="button" className="btn btn-danger me-2 btn-hover-scale">Собрать WOK</button>
        </div>
      </div>
    </div>
  );
}

CreateWokCard.defaultProps = {
  disableScretch: false,
};

CreateWokCard.propTypes = {
  disableScretch: PropTypes.bool,
};
