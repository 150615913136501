import {
  addWokToCart, clearCart, deleteWokFromCart,
} from '../../actions';
import { createReducer, forAction } from '../../utils';

const initialState = {};

const addToCartHandler = (state, { payload: wok }) => {
  if (state[wok.id]) {
    return ({
      ...state,
      [wok.id]: {
        ...state[wok.id],
        count: state[wok.id].count + 1,
      },
    });
  }
  return ({
    ...state,
    [wok.id]: {
      ...wok,
      count: 1,
    },
  });
};

const deleteFromCartHandler = (state, { payload: wok }) => {
  if (state[wok.id].count === 1) {
    const updated = { ...state };
    delete updated[wok.id];
    return updated;
  }
  return ({
    ...state,
    [wok.id]: {
      ...state[wok.id],
      count: state[wok.id].count - 1,
    },
  });
};

export default createReducer(
  [
    forAction(addWokToCart, addToCartHandler),
    forAction(deleteWokFromCart, deleteFromCartHandler),
  ],
  initialState,
  [clearCart],
);
