import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setQueryCity } from '../../../actions';
import { fromStore } from '../../../selectors';

export function FilterCities() {
  const dispatch = useDispatch();
  const query = useSelector(fromStore.queryCitySelector);
  const loader = useSelector(fromStore.cityLoaderSelector);

  const onChangeQuery = (e) => {
    dispatch(setQueryCity(e.target.value));
  };

  return (
    <div className="w-100 position-relative">
      <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
          <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
        </svg>
      </span>

      <input disabled={loader} onChange={onChangeQuery} type="text" className="form-control form-control-lg form-control-solid px-15" value={query} placeholder="Введите название города..." />

      <span className="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5">
        <span className="spinner-border h-15px w-15px align-middle text-muted" />
      </span>

      <span className="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-5 d-none">
        <span className="svg-icon svg-icon-2 svg-icon-lg-1 me-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
          </svg>
        </span>
      </span>
    </div>
  );
}
