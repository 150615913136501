import React from 'react';
import { Bonus } from './Bonus';
import { Description } from './Description';
import { GoodsList } from './GoodsList';
import { Promocode } from './Promocode';

export function InfoStep() {
  return (
    <div className="current">
      <div className="w-100">
        <div className="pb-7">
          <h2 className="fw-bolder text-dark">Детали заказа</h2>
        </div>
      </div>
      <Bonus />
      <Promocode />
      <GoodsList />
      <Description />
    </div>
  );
}
