import { combineReducers } from 'redux';
import method from './method';
import changeCash from './changeCash';
import email from './email';

export default combineReducers({
  method,
  changeCash,
  email,
});
