import { changeLogin, logoutAction, setOrderPhone } from '../../actions';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = '';

export default createReducer(
  [
    forAction(changeLogin, identityPayload),
    forAction(setOrderPhone, identityPayload),
  ],
  initialState,
  [logoutAction],
);
