import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { FranchiseBanner, Sidebar } from '../components';
import { routesConfig } from '../constants';
import { NewsDetail, NewsList } from '../containers';

export default function BlogPage() {
  const pages = Object.keys(routesConfig)
    .filter((key) => routesConfig[key].group === 1)
    .map((key) => routesConfig[key]);

  return (
    <>
      <Sidebar pages={pages} banner={<FranchiseBanner />} />
      <Routes>
        <Route path="/" element={<NewsList />} />
        <Route path=":id" element={<NewsDetail />} />
      </Routes>
    </>
  );
}
