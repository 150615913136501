import { setZones, setSelectedCity } from '../../actions';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = [];

export default createReducer(
  [forAction(setZones, identityPayload)],
  initialState,
  [setSelectedCity],
);
