import {
  cancelOrder, setOrderMessage,
} from '../../actions';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = '';

export default createReducer(
  [forAction(setOrderMessage, identityPayload)],
  initialState,
  [cancelOrder],
);
