import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchGoods } from '../actions';
import {
  CreateWokCard, FranchiseBanner, Loader,
} from '../components';
import { ADS } from '../containers';
import { fromStore } from '../selectors';
import { declOfNum, getImageUrl } from '../utils';

export default function HomePage() {
  const dispatch = useDispatch();
  const categories = useSelector(fromStore.denormalizedCategories);
  const goods = useSelector(fromStore.groupedGoodsList);
  const loader = useSelector(fromStore.goodsLoaderSelector);

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(fetchGoods());
    }
  }, []);

  if (loader) return <Loader list />;

  return (
    <div className="content flex-row-fluid border-0 p-0 mt-5 mt-lg-10">

      <div className="row g-5 g-xl-10 mb-10 flex-column-reverse flex-lg-row">

        <div className="col-xl-3">

          <div className="card border-0 h-100">

            <div className="rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start p-10" style={{ backgroundImage: "url('/assets/media/patterns/pattern-1.jpg')" }}>

              <h3 className="card-title align-items-start flex-column text-white">
                <span className="fw-bolder fs-2x mb-3">SUSHIBOX</span>
                <div className="fs-5 text-white">
                  <span className="">Скачивай наше приложение</span>
                </div>
              </h3>
              <div className="d-flex mt-10">
                <a target="_blank" href="https://apps.apple.com/us/app/sushi-box-доставка-еды/id6444746837" className="bg-white bg-hover-primary rounded" style={{ padding: '2px' }} rel="noreferrer">
                  <img className="w-100px" src="/assets/media/as-icon.png" alt="app store" />
                </a>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.ynbereg.sushibox.kz" className="bg-white bg-hover-danger rounded ms-3" style={{ padding: '2px' }} rel="noreferrer">
                  <img className="w-100px" src="/assets/media/gp-icon.png" alt="google play" />
                </a>
              </div>
            </div>
          </div>

        </div>

        <div className="col-xl-9">
          <ADS />
        </div>

      </div>
      <div className="row g-5 g-xl-10 flex-column-reverse flex-lg-row">
        <div className="col-xl-3">
          <CreateWokCard disableScretch />
          <FranchiseBanner />
        </div>
        <div className="col-xl-9">
          <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
            {categories.filter((item) => goods[item.id]).map((item) => (
              <Link key={item.key} to={`/menu/${item.key}`} className="col-sm-6 col-xxl-4">
                <div className="card card-flush h-xl-100">
                  <div className="card-body text-center p-5">
                    <div className="d-block overlay">
                      <div
                        className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded mb-5"
                        style={{ height: '150px', backgroundImage: `url(${getImageUrl(item.image)})` }}
                      />
                    </div>
                    <div className="">
                      <div className="">
                        <span className="fw-bolder text-gray-800 cursor-pointer text-hover-primary fs-2 d-block">{item.title}</span>
                        <span className="text-gray-400 mt-1 fw-bolder fs-6">{declOfNum(goods[item.id] ? goods[item.id].length : 0, ['позиция', 'позиции', 'позиций'])}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
