import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { getImageUrl, numberDevider } from '../../../../utils';
import { fromStore } from '../../../../selectors';

function UnitItem({ count }) {
  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px">
            <span className="symbol-label" style={{ backgroundImage: 'url("/assets/media/unit.jpg")' }} />
          </div>
          <div className="ms-3">
            <div className="fw-bolder text-gray-600 text-hover-primary text-overflow">Приборы</div>
            <div className="fs-7 text-muted" />
          </div>
        </div>
      </td>
      <td className="text-end">{`x${count}`}</td>
      <td className="text-end">0 ₸</td>
      <td className="text-end">0 ₸</td>
    </tr>
  );
}

UnitItem.propTypes = {
  count: PropTypes.string.isRequired,
};

function WokItem({ good }) {
  const {
    main, souce, meat, topping,
  } = good.wokData;
  const [firstSouce, secondSouce] = Object.values(souce);
  const title = `${main.title} + Овощи + Соус ${firstSouce.title}`;

  const getTopping = (toppings) => Object.values(toppings).map((item) => `${item.data.title.toLowerCase()} (${item.count})`).join(', ');

  const getSubtitle = () => {
    const result = [];
    if (secondSouce) {
      result.push(`доп. соус ${secondSouce.title.toLowerCase()}`);
    }
    if (!isEmpty(meat)) {
      result.push(getTopping(meat));
    }
    if (!isEmpty(topping)) {
      result.push(getTopping(topping));
    }
    return result.join(', ');
  };

  const subtitle = getSubtitle();

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px">
            <span className="symbol-label" style={{ backgroundImage: 'url("/assets/media/wok.jpg")' }} />
          </div>
          <div className="ms-3">
            <div className="fw-bolder text-gray-600 text-hover-primary text-overflow">{title}</div>
            <div className="fs-7 text-muted">{`Добавки: ${subtitle}`}</div>
          </div>
        </div>
      </td>
      <td className="text-end">{`x${good.count}`}</td>
      <td className="text-end">{`${good.price} ₸`}</td>
      <td className="text-end">{`${numberDevider(good.price * good.count)} ₸`}</td>
    </tr>
  );
}

WokItem.propTypes = {
  good: PropTypes.object.isRequired,
};

export function GoodItem({
  good, count, gift,
}) {
  const sale = useSelector(fromStore.salePricesGood);

  if (good.isWok) return <WokItem good={good} />;

  const salePrice = sale[good.id] && !gift ? sale[good.id].price : null;
  const price = salePrice || good.price;
  const { isStop } = good;

  /*
  useEffect(() => {
    if (isStop) {
      dispatch(deleteAllFromCart(good.id));
    }
  }, [isStop]);
  */

  return (
    <tr>
      <td>
        <div aria-hidden className="d-flex align-items-center cursor-pointer">
          <div>
            <img className="w-50px rounded" src={getImageUrl(good.image)} alt={good.name} />
          </div>
          <div className="ms-3">
            <div className="fw-bolder text-gray-600 text-hover-primary text-overflow mb-1">{good.name}</div>
            <div className="fs-7 text-muted  d-block">
              {good.category.title}
              {good.activeModName && <span className="badge badge-dark badge-sm ms-2 px-2 py-1">{`${good.activeModName}`}</span>}
              {gift && <span className="badge badge-danger badge-sm ms-2 px-2 py-1">FREE</span>}
            </div>
          </div>
        </div>
      </td>
      {isStop ? (
        <td colSpan={3}><div className="text-muted fw-bold d-block text-end">Товар недоступен</div></td>
      ) : (
        <>
          <td className="text-end">{`x${count}`}</td>
          {salePrice ? (
            <td className="text-end">
              <span className="text-danger me-3">{`${numberDevider(salePrice)} ₸`}</span>
              <span className="old-price">{`${numberDevider(good.price)} ₸`}</span>
            </td>
          ) : (
            <td className="text-end">{`${numberDevider(price)} ₸`}</td>
          )}
          <td className="text-end">{`${numberDevider(price * count)} ₸`}</td>
        </>
      )}
    </tr>
  );
}

GoodItem.defaultProps = {
  gift: false,
};

GoodItem.propTypes = {
  good: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  gift: PropTypes.bool,
};
