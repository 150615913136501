import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fromStore } from '../../selectors';
import { addToCart, deleteFromCart } from '../../actions';

export function AddCartButton({ id, isStop }) {
  const dispatch = useDispatch();
  const itemInCart = useSelector(fromStore.goodCartListSelector(id));

  const handleAddToCart = (e) => {
    e.stopPropagation();
    dispatch(addToCart(id));
  };

  const handleDeleteFromCart = (e) => {
    e.stopPropagation();
    dispatch(deleteFromCart(id));
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  if (isStop) {
    return (
      <div className="text-muted fw-bold d-block">Товар недоступен</div>
    );
  }

  if (itemInCart) {
    return (
      <div aria-hidden onClick={stopPropagation} className="position-relative">
        <button onClick={handleDeleteFromCart} type="button" className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0">
          <span className="svg-icon svg-icon-2x">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor" />
              <rect x="6.0104" y="10.9247" width="12" height="2" rx="1" fill="currentColor" />
            </svg>
          </span>
        </button>
        <input type="text" className="form-control h-45px fs-3 text-center sb-counter-bottom form-control-solid border-0" readOnly="readonly" value={itemInCart} />
        <button onClick={handleAddToCart} type="button" className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0">
          <span className="svg-icon svg-icon-2x">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor" />
              <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor" />
              <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor" />
            </svg>
          </span>
        </button>
      </div>
    );
  }

  return (
    <div aria-hidden onClick={handleAddToCart} className="btn btn-danger btn-hover-scale">
      <span className="svg-icon svg-icon-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor" />
          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor" />
        </svg>

      </span>
      В корзину
    </div>
  );
}

AddCartButton.defaultProps = {
  isStop: false,
};

AddCartButton.propTypes = {
  id: PropTypes.string.isRequired,
  isStop: PropTypes.bool,
};
