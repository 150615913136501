import Swal from 'sweetalert2';

const defaultConfig = {
  icon: 'error',
  confirmButtonText: 'Продолжить',
  customClass: { confirmButton: 'btn btn-primary' },
  buttonsStyling: false,
  allowOutsideClick: false,
  heightAuto: false,
};

export const showNotification = (params) => {
  const config = { ...defaultConfig, ...params };
  return Swal.fire(config);
};
