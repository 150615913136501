import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon, IconTypes } from '.';
import { getCookie, setCookie } from '../utils';

export function CookieNotify() {
  const [accept, setAccept] = useState(true);

  const onClose = () => {
    setCookie('policy', 'accept');
    setAccept(true);
  };

  useEffect(() => {
    const isAccept = getCookie('policy');
    if (isAccept) {
      setAccept(true);
    } else {
      setAccept(false);
    }
  }, []);

  if (accept) return null;

  return (
    <div className="d-flex d-lg-block flex-column fs-7 bg-dark text-light text-center rounded-0 py-5 show fixed-bottom">
      На нашем сайте используются файлы cookies, которые делают его более удобным для каждого
      пользователя.
      Посещая страницы сайта, вы соглашаетесь с нашей
      {' '}
      <Link to="/policy">Политикой конфиденциальности</Link>
      .
      <br />
      Подробнее ознакомиться с тем, для чего именно необходимы файлы сookies, можно
      {' '}
      <Link to="/policy">здесь</Link>
      <button onClick={onClose} style={{ top: '18px', right: '18px' }} type="button" className="btn btn-icon btn-sm position-absolute t-18px btn-hover-scale">
        <Icon className="svg-icon svg-icon-4x" type={IconTypes.close} />
      </button>
    </div>
  );
}
