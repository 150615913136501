import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  NavLink, Routes, Route, useNavigate, useLocation, Navigate,
} from 'react-router-dom';
import { Loader } from '../components';
import { GoodsList } from '../containers';
import { fromStore } from '../selectors';
import { getImageUrl } from '../utils';

function NotFound() {
  const { pathname } = useLocation();
  if (pathname === '/menu') return null;

  return <Navigate to="/404" replace />;
}

export default function MenuPage() {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const location = useLocation();
  const loader = useSelector(fromStore.goodsLoaderSelector);
  const categories = useSelector(fromStore.denormalizedCategories);
  const goods = useSelector(fromStore.groupedGoodsList);
  const categoryIdWok = useSelector(fromStore.getCategorySettingsId('wok'));
  const availableCategories = categories.filter((category) => goods[category.id]);

  useEffect(() => {
    if (availableCategories[0] && location.pathname === '/menu') {
      navigate(availableCategories[0].key);
      return;
    }

    if (!availableCategories.find((item) => `/menu/${item.key}` === location.pathname)) {
      navigate(availableCategories[0].key);
    }
  }, [availableCategories, location.pathname]);

  if (loader) {
    return <Loader list />;
  }

  const sidebar = (
    <div className="aside">
      <div className="hover-scroll-overlay-y my-5 my-lg-5 w-100 ps-4 ps-lg-0 pe-4 me-1">
        <div className="menu menu-column menu-active-bg menu-hover-bg menu-title-gray-700 fs-6 menu-rounded w-100">
          {availableCategories.map((category) => (
            <div key={category.key} className="menu-item">
              <NavLink to={category.key} className="menu-link">
                <img className="me-2 mw-25 mh-25px" src={getImageUrl(category.imagePrev)} alt={category.title} />
                <span className="menu-title ml-10">
                  {category.title}
                </span>
                <span className="menu-badge">{goods[category.id].length}</span>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const content = (
    <div className="wrapper d-flex flex-column flex-row-fluid mt-5 mt-lg-10">
      <div className="content position-relative flex-column-fluid">
        <Routes>
          {availableCategories.map((category) => (
            <Route
              key={category.key}
              path={category.key}
              element={(
                <GoodsList
                  count={goods[category.id].length}
                  goods={goods[category.id]}
                  title={category.title}
                  isWok={category.id === categoryIdWok}
                />
              )}

            />
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );

  return (
    <>
      {sidebar}
      {content}
    </>
  );
}
