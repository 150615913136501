export const host = 'https://sushibox.kz';
export const baseUrl = '/api';

export const restUrls = {
  singIn: '/auth/local',
  currentUser: '/users/me',
  userOrders: '/user/orders',
  users: '/users',
  register: '/auth/local/register',
  cities: '/cities_fields',
  filials: '/points',
  zone: '/zones',
  orders: '/orders',
  settings: '/settings',
  promocodes: '/promocodes',
  cashback: '/cashback',
  chanels: '/chanels',
  messages: '/messages',
  ratings: '/ratings',
  counter: '/counter',
  statistics: '/statistics',
  phonecodes: '/phonecodes',
  news: '/news',
  goods: '/goods',
  categories: '/categories',
  sendMail: '/mail/send',
  sliders: '/sliders',
};
