import { combineReducers } from 'redux';
import bonus from './bonus';
import promocode from './promocode';
import promocodeData from './promocodeData';

export default combineReducers({
  bonus,
  promocode,
  promocodeData,
});
