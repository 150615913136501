import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setOrderStep } from '../actions';
import { OrderStepper } from '../containers';

export default function OrderPage() {
  const dispatch = useDispatch();
  useEffect(() => () => dispatch(setOrderStep('1')), []);

  return (
    <div style={{ flexShrink: 1 }} className="post d-flex flex-column-fluid mt-10">
      <div className="container-xxl p-0 pb-10">
        <h1 className="fs-2x text-dark mb-7">Оформление заказа</h1>
        <OrderStepper />
      </div>
    </div>
  );
}
