import { changeAuthStep, setActiveModalAction } from '../../actions';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = '1';

export default createReducer(
  [forAction(changeAuthStep, identityPayload)],
  initialState,
  [setActiveModalAction],
);
