import { setUser, updateUser } from '../../actions';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = null;

export default createReducer(
  [
    forAction(setUser, identityPayload),
    forAction(updateUser, identityPayload),
  ],
  initialState,
);
