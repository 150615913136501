import {
  takeLatest, put, call, select,
} from 'redux-saga/effects';
import { fetchNotifications, loaderAction, setNotifications } from '../actions';
import { restUrls } from '../constants';
import { fromStore } from '../selectors';
import { request } from '../utils';

function generateFilterParams(user, city) {
  const newParams = {
    status: 'sended',
    chl_containss: ['all'],
  };

  if (user) {
    newParams.chl_containss.push(`user:${user.id}`);
    newParams.chl_containss.push('users');
  }

  if (city && city.id) {
    newParams.chl_containss.push(`city:${city.id}`);
  }
  return newParams;
}

function* fetchNotificationsHandler() {
  yield put(loaderAction({ field: 'notifications', value: true }));
  const city = yield select(fromStore.selectedCitySelector);
  const user = yield select(fromStore.userSelector);
  const params = generateFilterParams(user, city);
  const paramsList = {
    ...params,
    _sort: 'id:desc',
  };
  const notifications = yield call(request, { method: 'get', url: `${restUrls.messages}`, params: paramsList });
  yield put(setNotifications(notifications));
  yield put(loaderAction({ field: 'notifications', value: false }));
}

export default [
  takeLatest(fetchNotifications, fetchNotificationsHandler),
];
