import {
  cancelOrder, setOrderDeliveryAdress, setOrderDeliveryMethod, setOrderDeliveryPoint,
  setOrderDeliveryTime,
} from '../../../actions';
import { identityPayload, createReducer, forAction } from '../../../utils';

const initialState = new Date();

export default createReducer(
  [forAction(setOrderDeliveryTime, identityPayload)],
  initialState,
  [cancelOrder, setOrderDeliveryMethod, setOrderDeliveryAdress, setOrderDeliveryPoint],
);
