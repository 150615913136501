import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import calendar from 'dayjs/plugin/calendar';
import cn from 'classnames';
import { formatPhone, getAdress } from '../../../utils';
import { LONG_DASH, ORDER_VALUE_MAPS } from '../../../constants';
import { Icon, IconTypes } from '../../../components';

dayjs.extend(calendar);

export function OrderInfo({ order }) {
  const deliveryTime = order.deliveryTime ? dayjs(order.deliveryTime).calendar(null, {
    sameDay: '[Сегодня] в H:mm',
    nextDay: '[Завтра] в H:mm',
    sameElse: 'D MMMM в H:mm',
  }) : 'Как можно скорее';

  const renderAdress = () => {
    if (!order.city) return '-';
    if (order.deliveryMethod === 'own') {
      return getAdress(order.point, order.city.name);
    }
    return getAdress(order, order.city.name);
  };

  const getStatusTitle = useMemo(() => {
    if (order.status === 'done') return 'Начислено бонусов';
    if (order.status === 'new' || order.status === 'work') return 'Будет начислено бонусов';

    return null;
  }, [order.status]);

  const renderStatusPayment = () => {
    if (!order.paymentId) return 'Не оплачено';
    const paymentData = JSON.parse(order.paymentData);
    if (!paymentData) return 'Не оплачено';
    if (paymentData.status === 'pending') {
      const link = paymentData.confirmation.confirmation_url;
      return (
        <div>
          Ожидает оплаты
          <a href={link} className="btn btn-sm btn-danger ms-3">Оплатить</a>
        </div>
      );
    }

    if (paymentData.status === 'succeeded') return 'Оплачено';
    if (paymentData.status === 'refundPending') return 'Возврат средств';
    if (paymentData.status === 'refund') return 'Возврат средств выполнен';

    return '';
  };

  const detailOrder = (
    <div className="card card-flush flex-row-fluid border">
      <div className="card-header">
        <div className="card-title">
          <h2>Детали заказа</h2>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="table-responsive">
          <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
            <tbody className="fw-bold text-gray-800">
              <tr>
                <td className="text-muted">
                  <div className="d-flex align-items-center">
                    <Icon type={IconTypes.calendar} className="svg-icon svg-icon-2 me-2" />
                    Дата создания
                  </div>
                </td>
                <td className="fw-bolder text-end">{dayjs(order.created_at).format('D MMMM YYYY в HH:mm')}</td>
              </tr>
              <tr>
                <td className="text-muted">
                  <div className="d-flex align-items-center">
                    <Icon type={IconTypes.user} className="svg-icon svg-icon-2 me-2" />
                    Имя заказчика
                  </div>
                </td>
                <td className="fw-bolder text-end">
                  <div className="d-flex align-items-center justify-content-end">
                    <span className="text-hover-primary">{order.userName}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="text-muted">
                  <div className="d-flex align-items-center">
                    <Icon type={IconTypes.phone} className="svg-icon svg-icon-2 me-2" />
                    Телефон
                  </div>
                </td>
                <td className="fw-bolder text-end">
                  {formatPhone(order.phone)}
                </td>
              </tr>
              <tr>
                <td className="text-muted">
                  <div className="d-flex align-items-center">
                    <Icon type={IconTypes.success} className="svg-icon svg-icon-2 me-2" />
                    Статус
                  </div>
                </td>
                <td className="fw-bolder text-end">
                  <span className={cn('badge fs-7 fw-bold', ORDER_VALUE_MAPS.status[order.status].style)}>
                    {ORDER_VALUE_MAPS.status[order.status].title}
                  </span>
                </td>
              </tr>
              {getStatusTitle && (
              <tr>
                <td className="text-muted">
                  <div className="d-flex align-items-center">
                    <Icon type={IconTypes.bonus} className="svg-icon svg-icon-2 me-2" />
                    {getStatusTitle}
                  </div>
                </td>
                <td className="fw-bolder text-end">
                  {order.cashback && order.cashback.level1 ? (
                    <div className="badge badge-success fs-7 fw-bold">{`${order.cashback.level1} Б.`}</div>
                  ) : LONG_DASH}
                </td>
              </tr>
              )}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const detailDelivery = (
    <div className="card card-flush flex-row-fluid border">
      <div className="card-header">
        <div className="card-title">
          <h2>Детали доставки</h2>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="table-responsive">
          <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
            <tbody className="fw-bold text-gray-800">
              <tr>
                <td className="text-muted">
                  <div className="d-flex align-items-center">
                    <Icon type={IconTypes.delivery} className="svg-icon svg-icon-2 me-2" />
                    Способ доставки
                  </div>
                </td>
                <td className="fw-bolder text-end">{ORDER_VALUE_MAPS.deliveryMethod[order.deliveryMethod] || LONG_DASH}</td>
              </tr>
              <tr>
                <td className="text-muted">
                  <div className="d-flex align-items-center">
                    <Icon type={IconTypes.point} className="svg-icon svg-icon-2 me-2" />
                    Адрес
                  </div>
                </td>
                <td className="fw-bolder text-end">{renderAdress()}</td>
              </tr>
              <tr>
                <td className="text-muted">
                  <div className="d-flex align-items-center">
                    <Icon type={IconTypes.time} className="svg-icon svg-icon-2 me-2" />
                    Время
                  </div>
                </td>
                <td className="fw-bolder text-end">{deliveryTime}</td>
              </tr>
              <tr>
                <td className="text-muted">
                  <div className="d-flex align-items-center">
                    <Icon type={IconTypes.payment} className="svg-icon svg-icon-2 me-2" />
                    Способ оплаты
                  </div>
                </td>
                <td className="fw-bolder text-end">
                  {ORDER_VALUE_MAPS.payment[order.payment] || LONG_DASH}
                  {order.changeCash && <span className="ms-2">{`(${ORDER_VALUE_MAPS.changeCash[order.changeCash]})`}</span>}
                </td>
              </tr>
              {order.payment === 'online' && (
                <tr>
                  <td className="text-muted">
                    <div className="d-flex align-items-center">
                      <Icon type={IconTypes.payment} className="svg-icon svg-icon-2 me-2" />
                      Статус оплаты
                    </div>
                  </td>
                  <td className="fw-bolder text-end">
                    {renderStatusPayment()}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  return (
    <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
      {detailOrder}
      {detailDelivery}
    </div>
  );
}

OrderInfo.propTypes = {
  order: PropTypes.object.isRequired,
};
