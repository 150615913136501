import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveModalAction } from '../../../../actions';
import { Icon, IconTypes } from '../../../../components';
import { fromStore } from '../../../../selectors';
import { getAdress } from '../../../../utils';
import { DeliveryPoint } from './DeliveryPoint';

export function Point() {
  const dispatch = useDispatch();
  const city = useSelector(fromStore.selectedCitySelector);
  const point = useSelector(fromStore.orderDeliveryPointSelector);

  const onOpenPointModal = () => {
    dispatch(setActiveModalAction({ field: 'point', value: true }));
  };

  const renderButtons = () => {
    if (!point) {
      return (
        <div className="col-lg-12 d-flex align-items-center">
          <button onClick={onOpenPointModal} type="button" className="btn btn-danger btn-sm py-2 btn-hover-rise">Выбрать пункт самовывоза</button>
        </div>
      );
    }

    return (
      <div className="col-lg-12 d-flex align-items-center border border-secondary rounded ps-5 pe-1 py-1 flex-stack">
        <span className="fw-bolder fs-5 text-gray-800">{getAdress(point, city.name)}</span>
        <button onClick={onOpenPointModal} type="button" className="btn btn-sm btn-icon btn-light btn-active-white ms-1 btn-hover-scale">
          <Icon type={IconTypes.edit} className="svg-icon svg-icon-3" />
        </button>
      </div>
    );
  };

  return (
    <div>
      <h3 className="mb-5 required">Пункт самовывоза</h3>
      {renderButtons()}
      <DeliveryPoint />
    </div>
  );
}
