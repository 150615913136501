/* eslint-disable no-unused-vars */
import React from 'react';
import { FavoriteList, UserPageContent } from '../containers';

export default function FavoritesPage() {
  return (
    <UserPageContent title="Избранное">
      <FavoriteList />
    </UserPageContent>
  );
}
