import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { steps } from './config';
import { fromStore } from '../../selectors';

export function Aside() {
  const activeStep = useSelector(fromStore.orderStepSelector);

  return (
    <div className="d-lg-flex d-none card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9">
      <div className="card-body px-6 px-lg-10 px-xxl-15 py-20">
        <div className="stepper-nav">
          {Object.entries(steps).map(([step, values], index) => (
            <div key={step} className={cn('stepper-item', { current: activeStep === step, completed: index + 1 < +activeStep })}>
              <div className="stepper-line w-40px" />
              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check" />
                <span className="stepper-number">{index + 1}</span>
              </div>
              <div className="stepper-label">
                <h3 className="stepper-title">{values.title}</h3>
                <div className="stepper-desc fw-bold">{values.subtitle}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
