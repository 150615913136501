import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import {
  authUser, changeLogin, changeUserCode, setActiveModalAction,
} from '../../actions';
import { Modal } from '../../components';
import { fromStore } from '../../selectors';
import { PHONE_REG_EXP } from '../../constants';
import { regexpPhone } from '../../utils';

const initialValues = ['', '', '', '', ''];

function ConfirmStep() {
  const ref = useRef();
  const dispatch = useDispatch();
  const loader = useSelector(fromStore.authLoaderSelector);
  const error = useSelector(fromStore.errorAuthSelector);
  const login = useSelector(fromStore.userLoginSelector);
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    ref.current.childNodes[0].focus();
  }, [ref]);

  useEffect(() => {
    if (error) {
      setValues(initialValues);
      ref.current.childNodes[0].focus();
    }
  }, [error]);

  const onChangeValue = (index) => (e) => {
    const updatedValues = [...values];
    updatedValues[index] = e.target.value;
    setValues(updatedValues);
    if (index !== 4 && e.target.value) {
      ref.current.childNodes[index + 1].focus();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const code = values.join('');
    dispatch(authUser({ login, code }));
  };

  const renderError = (
    <div className="alert alert-danger d-flex align-items-center p-5 mb-10">
      <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
          <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor" />
          <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor" />
        </svg>
      </span>
      <div className="d-flex flex-column">
        <h4 className="mb-1 text-danger">Некорректный код подтверждения</h4>
        <span>Введите корректный код подверждения из SMS</span>
      </div>
    </div>
  );

  return (
    <form onSubmit={onSubmit} className="form w-100 mb-10">
      <div className="text-center mb-10">
        <img alt="Logo" className="mh-125px" src="/assets/media/svg/misc/smartphone.svg" />
      </div>
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Код подтверждения</h1>
        <div className="text-muted fw-bold fs-5 mb-5">Введите код подтверждения и нажмите отправить</div>
        <div className="fw-bolder text-dark fs-3">
          +7******
          {regexpPhone(login).slice(-4)}
        </div>
      </div>
      <div className="mb-10 px-md-10">
        <div className="fw-bolder text-start text-dark fs-6 mb-1 ms-1">Введите ваш 5-ти значный код</div>
        <div ref={ref} className="d-flex flex-wrap flex-stack">
          <input onChange={onChangeValue(0)} type="text" maxLength="1" className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" value={values[0]} />
          <input onChange={onChangeValue(1)} type="text" maxLength="1" className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" value={values[1]} />
          <input onChange={onChangeValue(2)} type="text" maxLength="1" className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" value={values[2]} />
          <input onChange={onChangeValue(3)} type="text" maxLength="1" className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" value={values[3]} />
          <input onChange={onChangeValue(4)} type="text" maxLength="1" className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" value={values[4]} />
        </div>
      </div>
      {error && renderError}
      <div className="d-flex flex-center">
        <button disabled={values.some((item) => !item) || loader} type="submit" className="btn btn-lg btn-primary fw-bolder">
          {loader ? (
            <span>
              Отправляем...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          ) : (<span className="indicator-label">Отправить</span>)}
        </button>
      </div>
    </form>
  );
}

export function AuthModal() {
  const dispatch = useDispatch();
  const ref = useRef();
  const login = useSelector(fromStore.userLoginSelector);
  const userCode = useSelector(fromStore.userCodeSelector);
  const loader = useSelector(fromStore.authLoaderSelector);
  const step = useSelector(fromStore.stepAuthSelector);

  const onClose = () => dispatch(setActiveModalAction({ field: 'auth', value: false }));
  const onChangeLogin = (e) => dispatch(changeLogin(e.target.value));
  const onChangeCode = (e) => dispatch(changeUserCode(e.target.value));
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(authUser({ login, userCode }));
  };

  useEffect(() => {
    ref.current.focus();
  }, [ref]);

  const firstStep = (
    <div data-kt-element="sms">
      <h3 className="text-dark fw-bolder fs-3 mb-5">Введите свой номер телефона, чтобы войти</h3>
      <div className="text-muted fw-bold mb-10">На указанный номер поступит смс</div>
      <form onSubmit={onSubmit} className="form">
        <div className="mb-10 fv-row">
          <InputMask mask="+7 (999) 999-99-99" value={login} onChange={onChangeLogin}>
            <input
              ref={ref}
              onChange={onChangeLogin}
              value={login}
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Введите номер телефона..."
            />
          </InputMask>
        </div>
        <div className="border-dashed rounded p-7 mb-10">
          <h3 className="text-dark fw-bolder mb-3">Бонусы SUSHIBOX</h3>
          <div className="text-gray-500 fw-bold fs-6 mb-5">
            Если у вас есть промокод друга, введите его в поле и получайте бонусы
          </div>
          <input
            value={userCode}
            onChange={onChangeCode}
            type="text"
            minLength={3}
            maxLength={10}
            className="form-control form-control-lg form-control-solid"
            placeholder="Введите промокод друга"
          />
        </div>
        <div className="d-flex flex-center">
          <button onClick={onClose} type="button" className="btn btn-light me-3">Отмена</button>
          <button type="submit" disabled={!login || loader || !PHONE_REG_EXP.test(login)} className="btn btn-primary">
            {loader ? (
              <span>
                Отправляем...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            ) : (<span className="indicator-label">Отправить</span>)}
          </button>
        </div>
      </form>
    </div>
  );

  return (
    <Modal title="Вход" onClose={onClose}>{step === '2' ? <ConfirmStep /> : firstStep}</Modal>
  );
}
