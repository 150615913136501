/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React from 'react';
import wNumb from 'wnumb';
import PropTypes from 'prop-types';
import Nouislider from 'react-nouislider';
import 'react-nouislider/node_modules/nouislider/distribute/nouislider.css';

export function Slider({
  value, maxValue, onChange, disabled,
}) {
  const onChangeHandler = ([item]) => {
    onChange(parseInt(item, 10));
  };

  return (
    <Nouislider
      disabled={disabled}
      animate
      step={2}
      range={{ min: 0, max: maxValue }}
      start={[value]}
      connect={[true, false]}
      onChange={onChangeHandler}
      tooltips={[{
        to: wNumb({
          mark: '.',
          thousand: ',',
          suffix: ' Б.',
          decimals: 0,
        }).to,
      }]}
    />

  );
}

Slider.propTypes = {
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
