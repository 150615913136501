import { setAuthError, logoutAction } from '../../actions';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = null;

export default createReducer(
  [forAction(setAuthError, identityPayload)],
  initialState,
  [logoutAction],
);
