import React, { useEffect, useState } from 'react';
import Carousel, { Dots, autoplayPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { fromStore } from '../selectors';
import { CartGood } from './cart/CartGood';

export function RecomendedGoods() {
  const [value, setValue] = useState(0);
  const goods = useSelector(fromStore.recomendedGoodsSelector);
  const isActive = useSelector(fromStore.activeCartSelector);

  useEffect(() => {
    if (isActive) {
      setValue(0);
    }
  }, [isActive]);

  if (!isActive || isEmpty(goods)) return null;

  const slides = goods.map((item) => <CartGood className="d-flex flex-stack w-100" item={item} />);

  const onChange = (valueItem) => {
    setValue(valueItem);
  };

  return (
    <div className="py-5 border-bottom">
      <div className="d-flex flex-stack mb-5">
        <div className="fs-3 fw-bold">Рекомендуем добавить</div>
        <Dots
          className="custom-dot"
          number={slides.length}
          value={value}
          onChange={onChange}
        />
      </div>
      <div style={{ display: 'grid' }}>
        <Carousel
          value={value}
          slides={slides}
          onChange={onChange}
          plugins={[
            {
              resolve: autoplayPlugin,
              options: {
                interval: 10000,
              },
            },
          ]}
          animationSpeed={500}
        />
      </div>
    </div>
  );
}
