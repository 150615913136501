import { createAction } from 'redux-actions';
import { makeActionType } from '../utils';

const namespace = 'USER';

export const authUser = createAction(makeActionType(namespace, ['AUTH']));

export const authSuccess = createAction(makeActionType(namespace, ['AUTH', 'SUCCESS']));

export const fetchUser = createAction(makeActionType(namespace, ['FETCH']));

export const setUser = createAction(makeActionType(namespace, ['SET']));

export const fetchOrdersUser = createAction(makeActionType(namespace, ['FETCH_ORDERS']));

export const updateUser = createAction(makeActionType(namespace, ['UPDATE']));

export const updateUserData = createAction(makeActionType(namespace, ['UPDATE', 'DATA']));

export const updateAdressData = createAction(makeActionType(namespace, ['UPDATE', 'ADRESS']));

export const updateUserPoint = createAction(makeActionType(namespace, ['UPDATE', 'POINT']));

export const changeLogin = createAction(makeActionType(namespace, ['CHANGE', 'LOGIN']));

export const changeUserCode = createAction(makeActionType(namespace, ['CHANGE', 'CODE']));

export const setAuthError = createAction(makeActionType(namespace, ['AUTH', 'ERROR']));

export const changeAuthStep = createAction(makeActionType(namespace, ['CHANGE', 'AUTH_STEP']));

export const logoutAction = createAction(makeActionType(namespace, ['AUTH', 'LOGOUT']));
