import { createSelector } from 'reselect';
import { citiesSelector } from './root';
import { propNameSelector } from '../utils';

export const selectedCitySelector = propNameSelector(citiesSelector, 'selected');
export const queryCitySelector = propNameSelector(citiesSelector, 'query');
export const citiesListSelector = propNameSelector(citiesSelector, 'list');
export const selectedZonesSelector = propNameSelector(citiesSelector, 'selectedZones');

export const filteredCitiesListSelector = createSelector(
  citiesListSelector,
  queryCitySelector,
  (list, query) => list.filter((item) => item.name.toLowerCase().includes(query.toLowerCase())),
);

export const getCityByIdSelector = (id) => createSelector(
  citiesListSelector,
  (list) => list.find((item) => item.id === id),
);
