import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  setOrderDeliveryAdress, setOrderDeliveryPoint, setOrderDeliveryZone,
} from '../../../../actions';
import { fromStore } from '../../../../selectors';
import { userSelector } from '../../../../selectors/auth';
import { SelectDeliveryMethod } from './SelectDeliveryMethod';
import { Adress } from './Adress';
import { Point } from './Point';
import { ErrorBlock } from './ErrorBlock';
import { SelectTimeMethod } from './SelectTimeMethod';

export function DeliveryMethodStep() {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const deliveryMethod = useSelector(fromStore.orderDeliveryMethodSelector);
  const point = useSelector(fromStore.orderDeliveryPointSelector);
  const adress = useSelector(fromStore.orderDeliveryAdressSelector);

  const userPoint = user && !isEmpty(user.points) ? user.points[0] : null;
  const userAdress = user ? {
    privateHouse: user.privateHouse,
    street: user.street,
    house: user.house,
    room: user.room,
    floor: user.floor,
    area: user.area,
    entrance: user.entrance,
  } : null;

  useEffect(() => {
    if (user) {
      dispatch(setOrderDeliveryPoint(userPoint));
      dispatch(setOrderDeliveryAdress(userAdress));
      dispatch(setOrderDeliveryZone(user.zone));
    }
  }, [user]);

  const renderDeliveryTime = () => {
    if (!deliveryMethod) return null;
    if (deliveryMethod === 'own' && !point) return null;
    if (deliveryMethod === 'delivery' && !adress.street) return null;

    return <SelectTimeMethod />;
  };

  const title = (
    <div className="pb-10">
      <h2 className="fw-bolder d-flex align-items-center text-dark">
        Выберите способ получения заказа
      </h2>
    </div>
  );

  return (
    <div className="current">
      <div className="w-100">
        {title}
        <div className="mb-10">
          <SelectDeliveryMethod />
          {deliveryMethod && (
            <div className="mt-10 mb-10">
              {deliveryMethod === 'delivery' && <Adress />}
              {deliveryMethod === 'own' && <Point />}
            </div>
          )}
          {renderDeliveryTime()}
          <ErrorBlock />
        </div>
      </div>
    </div>
  );
}
