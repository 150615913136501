import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Img } from 'react-image';
import { Loader } from '../../components';
import { getImageUrl } from '../../utils';

export function Slide({ name, image, click }) {
  const renderImage = useMemo(() => (
    <Img
      alt={name}
      src={getImageUrl(image)}
      className="mw-100 card-rounded"
      loader={<Loader image />}
      onClick={click}
    />
  ), [name, image, click]);

  return (
    <div className="text-center">
      {renderImage}
    </div>
  );
}

Slide.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  click: PropTypes.func.isRequired,
};
