import {
  cancelOrder, setOrderDeliveryAdress, setOrderDeliveryMethod, setOrderDeliveryPoint,
  setOrderDeliveryTimeMethod,
} from '../../../actions';
import { identityPayload, createReducer, forAction } from '../../../utils';

const initialState = 'fast';

export default createReducer(
  [forAction(setOrderDeliveryTimeMethod, identityPayload)],
  initialState,
  [cancelOrder, setOrderDeliveryMethod, setOrderDeliveryAdress, setOrderDeliveryPoint],
);
