import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { authSelector } from './root';
import { propNameSelector } from '../utils';

export const userSelector = propNameSelector(authSelector, 'user');
export const userLoginSelector = propNameSelector(authSelector, 'login');
export const userCodeSelector = propNameSelector(authSelector, 'code');
export const stepAuthSelector = propNameSelector(authSelector, 'step');
export const errorAuthSelector = propNameSelector(authSelector, 'error');

export const getOrderById = (id) => createSelector(
  userSelector,
  (user) => {
    if (!user || isEmpty(user.orders)) return null;

    return user.orders.find((item) => String(item.id) === id) || null;
  },
);
