/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Sidebar } from '../components';
import { routesConfig } from '../constants';

export default function PolicyPage() {
  const pages = Object.keys(routesConfig)
    .filter((key) => routesConfig[key].group === 1)
    .map((key) => routesConfig[key]);

  const content = (
    <div className="wrapper d-flex flex-column flex-row-fluid mt-5 mt-lg-10">
      <div className="content flex-column-fluid position-relative" id="kt_content">
        <div className="toolbar d-flex flex-stack flex-wrap mb-5 mb-lg-7">
          <div className="page-title d-flex flex-column py-1">
            <h1 className="d-flex align-items-center my-1">
              <span className="text-dark fw-bolder fs-1">Политика конфиденциальности</span>
            </h1>
          </div>
        </div>
        <div className="post abs pt-0 border rounded mb-10" id="kt_post">

          <div className="card border-0">

            <div className="card-body p-5 px-lg-19 py-lg-16">

              <div className="">
                <div className="mb-15">
                  <div className="fs-5 text-gray-600">
                    Настоящая политика конфиденциальности и обработки персональных данных регулирует порядок обработки и использования
                    персональных и иных данных сайта компании «SUSHIBOX» (дальше — Оператор). Действующая редакция настоящей
                    Политики конфиденциальности размещена в сети Интернет по адресу:
                    {' '}
                    <a href="https://sushibox.kz" rel="noreferrer" target="_blank">https://sushibox.kz</a>
                    <br />
                    <br />
                    Передавая Оператору персональные и иные данные посредством Сайта, Пользователь подтверждает свое согласие на использование указанных данных на условиях, изложенных в настоящей Политике конфиденциальности.
                    <br />
                    <br />
                    Если Пользователь не согласен с условиями настоящей Политики конфиденциальности, он обязан прекратить использование Сайта и/или Приложения.
                  </div>
                </div>

                <div className="mb-15">
                  <h2 className="fw-bolder text-dark mb-5">1. ТЕРМИНЫ</h2>
                  <div className="fs-5 text-gray-600">
                    1.1. Сайт - сайт, расположенный в сети Интернет по адресу
                    {' '}
                    <a href="https://sushibox.kz" rel="noreferrer" target="_blank">https://sushibox.kz</a>
                    . Все исключительные права на Сайт и его отдельные элементы (включая программное обеспечение, дизайн) принадлежат Оператору в полном объеме.
                    <br />
                    1.2. Приложение — сервис SUSHIBOX для мобильных устройств на базе ОС Android и iOS для оказания услуг Пользователю.
                    <br />
                    1.3. Пользователь — лицо использующее Сайт и/или Приложение.
                    <br />
                    1.4. Законодательство — действующее законодательство Республики Казахстан.
                    <br />
                    1.5. Персональные данные — персональные данные Пользователя, которые Пользователь предоставляет самостоятельно при регистрации или в процессе использования функционала Сайта или Приложения.
                    <br />
                    1.6. Данные — иные данные о Пользователе (не входящие в понятие Персональных данных).
                    <br />
                    1.7. Регистрация — заполнение Пользователем Регистрационной формы, расположенной на Сайте или Приложении, путем указания необходимых сведений и отправки сканированных документов.
                    <br />
                    1.8. Регистрационная форма — форма, расположенная на Сайте, которую Пользователь должен заполнить для возможности использования сайта или приложения в полном объеме.
                    <br />
                    1.9. Услуга(и) — услуги, предоставляемые Оператором на основании соглашения.
                  </div>
                </div>

                <div className="mb-15">
                  <h2 className="fw-bolder text-dark mb-5">2. СБОР И ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                  <div className="fs-5 text-gray-600">
                    2.1. Оператор собирает и хранит только те Персональные данные, которые необходимы для оказания Услуг Оператором и взаимодействия с Пользователем.
                    <br />
                    2.2. Персональные данные могут использоваться в следующих целях:
                    <br />
                    2.2.1 оказание Услуг Пользователю;
                    <br />
                    2.2.2 идентификация Пользователя;
                    <br />
                    2.2.3 взаимодействие с Пользователем;
                    <br />
                    2.2.4 направление Пользователю рекламных материалов, информации и запросов;
                    <br />
                    2.2.5 проведение статистических и иных исследований;
                    <br />
                    2.3. Оператор в том числе обрабатывает следующие данные:
                    <br />
                    2.3.1 имя Пользователя;2.3.2 адрес электронной почты;
                    <br />
                    2.3.3 номер телефона (в т.ч. мобильного).
                    <br />
                    2.4. Пользователю запрещается указывать на Сайте персональные данные третьих лиц(за исключением условия представления интересов этих лиц, имея документальное подтверждение третьих лиц на осуществление таких действий).
                  </div>
                </div>

                <div className="mb-15">
                  <h2 className="fw-bolder text-dark mb-5">3. ПОРЯДОК ОБРАБОТКИ ПЕРСОНАЛЬНЫХ И ИНЫХ ДАННЫХ</h2>
                  <div className="fs-5 text-gray-600">
                    3.1. Оператор обязуется использовать Персональные данные в соответствии с Законом «О персональных данных» Республики Казахстан и внутренними документами Оператора.
                    <br />
                    3.2. В отношении Персональных данных и иных Данных Пользователя сохраняется их конфиденциальность, кроме случаев, когда указанные данные являются общедоступными.
                    <br />
                    3.3. Оператор имеет право сохранять архивную копию Персональных данных.
                    <br />
                    3.4. Оператор имеет право передавать Персональные данные и Данные Пользователя без согласия Пользователя следующим лицам:
                    <br />
                    3.4.1 государственным органам, в том числе органам дознания и следствия, и органам местного самоуправления по их мотивированному запросу;
                    <br />
                    3.4.2 в иных случаях, прямо предусмотренных действующим законодательством Республики Казахстан.
                    <br />
                    3.5. Оператор имеет право передавать Персональные данные и Данные третьим лицам, не указанным в п.3.4. настоящей Политики конфиденциальности, в следующих случаях:
                    <br />
                    3.5.1 Пользователь выразил свое согласие на такие действия;
                    <br />
                    3.5.2 передача необходима в рамках использования Пользователем Сайта или оказания Услуг Пользователю;
                  </div>
                </div>

                <div className="mb-15">
                  <h2 className="fw-bolder text-dark mb-5">4. ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                  <div className="fs-5 text-gray-600">
                    4.1. Оператор осуществляет надлежащую защиту Персональных и иных данных в соответствии с Законодательством и принимает необходимые и достаточные организационные и технические меры для защиты Персональных данных.
                    4.2. Применяемые меры защиты в том числе позволяют защитить Персональные данные от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ними третьих лиц.
                  </div>
                </div>

                <div className="mb-15">
                  <h2 className="fw-bolder text-dark mb-5">5. ИНЫЕ ПОЛОЖЕНИЯ</h2>
                  <div className="fs-5 text-gray-600">
                    5.1. К настоящей Политике конфиденциальности и отношениям между Пользователем и Оператором, возникающим в связи с применением Политики конфиденциальности, подлежит применению право Республики Казахстан.
                    <br />
                    5.2. Все возможные споры, вытекающие из настоящего Соглашения, подлежат разрешению в соответствии с действующим законодательством по месту регистрации Оператора.Перед обращением в суд Пользователь должен соблюсти обязательный до судебный порядок и направить Оператору соответствующую претензию в письменном виде. Срок ответа на претензию составляет 30 (тридцать) рабочих дней.
                    <br />
                    5.3. Если по тем или иным причинам одно или несколько положений Политики конфиденциальности будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений Политики конфиденциальности.
                    <br />
                    5.4. Оператор имеет право в любой момент изменять Политику конфиденциальности (полностью или в части) в одностороннем порядке без предварительного согласования с Пользователем. Все изменения вступают в силу с момента ее размещения на Сайте.
                    <br />
                    5.5. Пользователь обязуется самостоятельно следить за изменениями Политики конфиденциальности путем ознакомления с актуальной редакцией.5.6. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать по электронной почте: info@sushibox.kz
                  </div>
                </div>

              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  );

  return (
    <>
      <Sidebar pages={pages} />
      {content}
    </>
  );
}
