import {
  cancelOrder, clearOrderPricesPromocode, setOrderPricesPromocode,
} from '../../../actions';
import { identityPayload, createReducer, forAction } from '../../../utils';

const initialState = '';

export default createReducer(
  [forAction(setOrderPricesPromocode, identityPayload)],
  initialState,
  [cancelOrder, clearOrderPricesPromocode],
);
