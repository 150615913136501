import axios from 'axios';
import { normalize, denormalize, schema } from 'normalizr';
import { getCookie, deleteCookie } from './cookie';
import { baseUrl, GEO_CONFIG } from '../constants';
import { showNotification } from './notifications';

const errorConfig = {
  title: 'Ошибка выполнения запроса',
  text: 'Попробуйте позднее',
  icon: 'error',
  confirmButtonText: 'Повторить',
  customClass: { confirmButton: 'btn btn-dark' },
};

export const request = async (options, needAuth = true, notifyConfig = {}, noRefresh = false) => {
  const jwt = getCookie('auth');
  const client = axios.create({
    baseURL: baseUrl,
    headers: jwt && needAuth ? {
      Authorization: `Bearer ${jwt}`,
    } : undefined,
  });

  try {
    const result = await client(options);
    return result.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      deleteCookie('auth');
      window.history.back();
      return null;
    }
    const confirm = await showNotification({
      ...errorConfig,
      text: error.message,
      ...notifyConfig,
    });

    if (confirm.isConfirmed) {
      if (!noRefresh) {
        await request(options, needAuth, notifyConfig);
      } else {
        return { error: 'needRefresh' };
      }
    }
    return { error: error.response };
  }
};

export const normalizeItems = (items, name) => {
  const itemSchema = new schema.Entity(name);
  const listSchema = [itemSchema];
  return normalize(items, listSchema);
};

export const denormalizeItems = (items, name, entities) => {
  const itemSchema = new schema.Entity(name);
  const listSchema = [itemSchema];
  return denormalize(items, listSchema, { [name]: entities });
};

export const getCityGeoposition = async () => {
  if ('geolocation' in navigator) {
    try {
      const geoposition = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, { timeout: 8000 });
      });

      if (geoposition) {
        const result = await axios.post(
          GEO_CONFIG.url,
          { lat: geoposition.coords.latitude, lon: geoposition.coords.longitude, count: 1 },
          {
            headers: {
              Authorization: `Token ${GEO_CONFIG.token}`,
            },
            timeout: 8000,
          },
        );
        if (result.data && result.data.suggestions && result.data.suggestions[0]) {
          const cityData = result.data.suggestions[0];
          return cityData.data.city;
        }
        return null;
      }
      return null;
    } catch (error) {
      return null;
    }
  }
  return null;
};
