import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useOnClickOutside } from '../hooks';

export function Modal(props) {
  const ref = useRef();
  const { title, children, onClose } = props;

  useOnClickOutside(ref, onClose);

  const header = useMemo(() => (
    <div className="modal-header flex-stack px-lg-17">
      <h2>{title}</h2>
      <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={onClose} aria-hidden>
        <span className="svg-icon svg-icon-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
          </svg>
        </span>
      </div>
    </div>
  ), [title, onClose]);

  return (
    <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered mw-650px">
        <div ref={ref} className="modal-content">
          {header}
          <div className="modal-body scroll-y pt-10 pb-15 px-lg-17">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
};
