import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { fromStore } from '../../../selectors';

export function CityItem({
  city, selected, last, onSelectCity,
}) {
  const loader = useSelector(fromStore.cityLoaderSelector);
  const onSelect = () => onSelectCity(city);

  const renderButtonText = () => {
    if (selected && loader) {
      return (
        <div>
          <div className="spinner-border text-light spinner-border-sm" role="status" />
        </div>
      );
    }
    if (selected) {
      return 'Выбрано';
    }

    return 'Выбрать';
  };

  return (
    <div className={cn('d-flex flex-stack py-4', { 'border-bottom border-gray-300 border-bottom-dashed': last })}>
      <div className="d-flex align-items-center">
        <div className="ms-5">
          <div className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2">{city.name}</div>
          <div className="fw-bold text-muted">{city.deliveryWork}</div>
        </div>
      </div>

      <div className="w-100px">
        <button
          disabled={selected || loader}
          type="button"
          onClick={onSelect}
          className={cn('btn d-flex justify-content-center w-100', selected ? 'btn-dark' : 'btn-light')}
        >
          {renderButtonText()}
        </button>
      </div>

    </div>
  );
}

CityItem.propTypes = {
  city: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  last: PropTypes.bool.isRequired,
  onSelectCity: PropTypes.func.isRequired,
};
