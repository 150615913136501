import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { setActiveModalAction, setOrderDeliveryPoint, updateUserPoint } from '../../../actions';
import { useOnClickOutside } from '../../../hooks';
import { fromStore } from '../../../selectors';
import { getAdress } from '../../../utils';

const getCoorditates = (coord) => coord.split(',');

export function PointModal() {
  const dispatch = useDispatch();
  const ref = useRef();
  const city = useSelector(fromStore.selectedCitySelector);
  const user = useSelector(fromStore.userSelector);
  const loader = useSelector(fromStore.userLoaderSelector);
  const orderPoint = useSelector(fromStore.orderDeliveryPointSelector);
  const userPoint = user && user.points && user.points[0];
  const [selectedPoint, setPoint] = useState(user ? userPoint : orderPoint || null);

  const cityСoordinates = getCoorditates(city.coord);
  const cityPoints = city.points || [];
  const mapCoordinates = selectedPoint ? getCoorditates(selectedPoint.coord) : cityСoordinates;
  const zoom = selectedPoint ? 14 : 12;

  const onClose = () => {
    if (loader) return;
    dispatch(setActiveModalAction({ field: 'point', value: false }));
  };

  const onSelectPoint = (point) => () => {
    if (loader) return;
    if (selectedPoint && selectedPoint.id === point.id) {
      setPoint(null);
    } else {
      setPoint(point);
    }
  };

  const onSave = () => {
    if (user) {
      dispatch(updateUserPoint(selectedPoint.id));
    } else {
      dispatch(setOrderDeliveryPoint(selectedPoint));
      onClose();
    }
  };

  useOnClickOutside(ref, onClose);

  const renderCloseButton = (
    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={onClose} aria-hidden>
      <span className="svg-icon svg-icon-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
          <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
        </svg>
      </span>
    </div>
  );

  const renderCityPoint = (point) => (
    <div
      key={`point_${point.id}`}
      aria-hidden
      onClick={onSelectPoint(point)}
      className={cn('nav-link btn border-hover-dark btn-outline btn-color-dark d-flex flex-stack text-start p-6 mb-5', {
        'border-success btn-outline-solid': selectedPoint && selectedPoint.id === point.id,
        'btn-outline-dashed': !selectedPoint || selectedPoint.id !== point.id,
      })}
    >
      <div className="d-flex align-items-center me-2">
        <div className="form-check form-check-custom form-check-solid form-check-success me-6">
          <input className="form-check-input" type="radio" checked={selectedPoint && point.id === selectedPoint.id} />
        </div>
        <div className="flex-grow-1">
          <h2 className="d-flex align-items-center fs-5 fw-bolder flex-wrap">{getAdress(point)}</h2>
          <div className="fw-bold opacity-50">{point.phone}</div>
          <div className="fw-bold opacity-50">{point.email}</div>
          <div className="fw-bold opacity-75 mt-2">{point.timeWork}</div>
        </div>
      </div>
    </div>
  );

  const emptyResult = (
    <div className="fw-bold">
      <div className="text-gray-700 fs-3 mb-2">Пока что нет пунктов самовывоза</div>
    </div>
  );

  return (
    <div className="modal fade d-block show">
      <div className="modal-dialog modal-lg mw-1000px">
        <div ref={ref} className="modal-content rounded">
          <div className="modal-header">
            <div className="d-flex">
              <h2>Выберите пункт самовывоза</h2>
              <span className="badge badge-dark badge-lg ms-5">{city.name}</span>
            </div>

            {renderCloseButton}
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-5 mb-10 mb-lg-0">
                <div className="d-flex flex-column min-h-300px mh-500px scroll-y pe-2">
                  {cityPoints.length > 0 ? cityPoints.map(renderCityPoint) : emptyResult}
                </div>
              </div>
              <div className="col-lg-7">
                <YMaps>
                  <Map width="100%" height="100%" state={{ center: mapCoordinates, zoom }}>
                    {
                      selectedPoint && <Placemark geometry={getCoorditates(selectedPoint.coord)} />
                    }
                  </Map>
                </YMaps>
              </div>
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-end">
            <button type="button" disabled={loader} onClick={onClose} className="btn btn-active-light me-5">Отмена</button>
            <button onClick={onSave} disabled={loader || !selectedPoint || (userPoint && selectedPoint.id === userPoint.id)} type="button" className="btn btn-danger">
              {loader ? (
                <span className="indicator-progress d-block">
                  Сохрание...
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              ) : (
                <span className="indicator-label">Сохранить</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
