import {
  takeLatest, put, call, all,
} from 'redux-saga/effects';

import {
  initAction, setSettings, loaderAction, initSuccessAction,
} from '../actions';
import { restUrls } from '../constants';
import { request } from '../utils';
import { fetchCurrentUser } from './auth';
import { fetchCitiesHandler } from './city';
import { onFetchGoods } from './goods';

function* fetchSettingsHandler() {
  const settings = yield call(request, { method: 'get', url: `${restUrls.settings}` });
  yield put(setSettings(settings[0]));
}

function* initApp() {
  yield put(loaderAction({ field: 'global', value: true }));
  const result = yield all([
    fetchCurrentUser(),
    fetchCitiesHandler(),
    fetchSettingsHandler(),
    onFetchGoods(),
  ]);

  if (result.every((item) => item === undefined)) {
    yield put(initSuccessAction());
    yield put(loaderAction({ field: 'global', value: false }));
  }
}

export default [
  takeLatest(initAction, initApp),
];
