import {
  cancelOrder, setOrderStep, setSelectedCity,
} from '../../actions';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = '1';

export default createReducer(
  [forAction(setOrderStep, identityPayload)],
  initialState,
  [cancelOrder, setSelectedCity],
);
