import {
  CustomerStep, DeliveryMethodStep, InfoStep, PaymentStep,
} from './steps';

export const steps = {
  1: { title: 'Данные заказчика', subtitle: 'Введите данные заказчика', component: CustomerStep },
  2: { title: 'Способ получения', subtitle: 'Выберите способ получения заказа', component: DeliveryMethodStep },
  3: { title: 'Способ оплаты', subtitle: 'Выберите способ оплаты заказа', component: PaymentStep },
  4: { title: 'Детали заказа', subtitle: 'Проверьте заказ', component: InfoStep },
};
