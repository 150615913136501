import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderPricesBonus } from '../../../../actions';
import { Slider } from '../../../../components';
import { fromStore, settingsSelector } from '../../../../selectors';
import { declOfNum } from '../../../../utils';

export function Bonus() {
  const dispatch = useDispatch();
  const user = useSelector(fromStore.userSelector);
  const сostGoods = useSelector(fromStore.totalGoodsPriceWithSaleSelector);
  const bonus = useSelector(fromStore.orderPricesBonusSelector);
  const { bonusOrderPersent } = useSelector(settingsSelector);
  const createLoader = useSelector(fromStore.createOrderLoaderSelector);

  if (!user || сostGoods === 0) return null;

  if (!+user.balance) return null;

  const balance = parseInt(user.balance, 10);

  const availableCostForBonus = Math.round(сostGoods * (bonusOrderPersent / 100));
  const availableBonus = balance < availableCostForBonus ? balance : availableCostForBonus;

  const onChange = (value) => {
    dispatch(setOrderPricesBonus(value));
  };

  useEffect(() => {
    if (bonus > availableBonus) {
      dispatch(setOrderPricesBonus(availableBonus));
    }
  }, [availableBonus]);

  return (
    <div className="mb-7 border px-7 pt-7 pb-5 rounded pe-10">
      <h4 className="mb-2 d-flex flex-stack">
        Бонусы SUSHIBOX
        {' '}
        <span className="badge badge-success fw-bolder fs-7 ms-2">{declOfNum(balance, ['бонус', 'бонуса', 'бонусов'])}</span>
      </h4>
      <div className="fs-6 mb-11">
        {`Можно оплатить ${bonusOrderPersent}% от суммы заказа`}
      </div>
      <div className="w-100 d-flex flex-stack">
        <div className="fs-6 fw-bold" style={{ whiteSpace: 'nowrap' }}>Списать</div>
        <div className="w-100 ms-10">
          <Slider
            disabled={createLoader}
            value={bonus}
            maxValue={availableBonus}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="text-muted text-end fs-7 mt-3">Передвиньте ползунок для выбора значения*</div>
    </div>
  );
}
