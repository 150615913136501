import { all } from 'redux-saga/effects';
import appSagas from './app';
import goodsSagas from './goods';
import authSagas from './auth';
import citySagas from './city';
import orderSagas from './order';
import newsSagas from './news';
import notificationsSagas from './notifications';
import feedbackSagas from './feedback';
import slidersSagas from './sliders';

const sagas = [
  ...appSagas,
  ...goodsSagas,
  ...authSagas,
  ...citySagas,
  ...orderSagas,
  ...newsSagas,
  ...notificationsSagas,
  ...feedbackSagas,
  ...slidersSagas,
];

export default function* rootSaga() {
  yield all(sagas);
}
