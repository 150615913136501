import React from 'react';
import { OrdersList } from '../../containers/orders';

export function OrdersListPage() {
  const title = (
    <div className="toolbar d-flex flex-stack flex-wrap pb-5 pb-lg-5 border-bottom">
      <div className="page-title d-flex flex-column py-1">
        <h1 className="d-flex align-items-center my-1">
          <span className="text-dark fw-bolder fs-1">Мои заказы</span>
        </h1>
      </div>
    </div>
  );

  return (
    <>
      {title}
      <OrdersList />
    </>
  );
}
