import { createSelector } from 'reselect';
import { settingsSelector } from './root';
import { propNameSelector } from '../utils';

export const categorySettingsSelector = propNameSelector(settingsSelector, 'categories');

export const getCategorySettingsId = (category) => createSelector(
  categorySettingsSelector,
  (categorySettings) => {
    const settings = categorySettings || {};
    return settings[category] || 100;
  },
);
