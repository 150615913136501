import { setEntities } from '../actions';
import { createReducer, forAction } from '../utils';

const initialState = {};

export default createReducer(
  [forAction(setEntities, (state, { payload: entities }) => ({
    ...state,
    ...entities,
  }))],
  initialState,
);
