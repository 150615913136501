import { cancelOrder, setOrderDeliveryMethod } from '../../../actions';
import { identityPayload, createReducer, forAction } from '../../../utils';

const initialState = '';

export default createReducer(
  [forAction(setOrderDeliveryMethod, identityPayload)],
  initialState,
  [cancelOrder],
);
