import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Loader } from '../../components';
import { fromStore, newsSelector } from '../../selectors';
import { fetchNews } from '../../actions';
import { getImageUrl } from '../../utils';
import { DATE_FORMAT_SHORT } from '../../constants';

export function NewsList() {
  const dispatch = useDispatch();
  const loader = useSelector(fromStore.newsLoaderSelector);
  const news = useSelector(newsSelector);

  useEffect(() => {
    if (news.length === 0) {
      dispatch(fetchNews());
    }
  }, []);

  if (loader) return <Loader list />;

  const emptyResult = (
    <div className="fw-bold">
      <div className="text-gray-700 fs-3 mb-2">Новостей пока нет</div>
    </div>
  );

  const renderItem = (item) => (
    <div key={`news_${item.id}`} className="col-md-4">
      <div className="card h-100">
        <div className="card-body p-5 d-flex flex-column justify-content-between">
          <div>
            <img className="w-100 rounded p-10" src={getImageUrl(item.image)} alt="1231" />
            <div className="mt-5">
              <div>
                <Link to={`/blog/${item.id}`} className="fs-4 text-dark fw-bolder text-hover-primary text-dark lh-base">{item.title}</Link>
                <div className="fs-5 text-gray-600 text-dark mt-3">{item.description}</div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="fs-6 mt-5 d-flex flex-stack">
              <Link to={`/blog/${item.id}`} className="btn btn-sm btn-dark btn-hover-scale">Подробнее</Link>
              <span className="fw-normal text-muted fs-6">{dayjs(item.created_at).format(DATE_FORMAT_SHORT)}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  );

  return (
    <div className="wrapper d-flex flex-column flex-row-fluid mt-5 mt-lg-10">
      <div className="content flex-column-fluid" id="kt_content">
        <div className="toolbar d-flex flex-stack flex-wrap mb-5 mb-lg-7">
          <div className="page-title d-flex flex-column py-1">
            <h1 className="d-flex align-items-center my-1">
              <span className="text-dark fw-bolder fs-1">Новости</span>
            </h1>
          </div>
        </div>
        <div className="post">
          <div className="row gy-0">
            {isEmpty(news) ? emptyResult : news.map(renderItem)}
          </div>
        </div>
      </div>
    </div>
  );
}
