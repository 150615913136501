import { isEmpty } from 'lodash';
import { getModifiedGood } from './common';

export const getIdModGood = (activeMod, id) => `${id}_${activeMod.id}`;

export const getIdGood = (value, article) => {
  if (article) {
    return String(article);
  }
  return String(value).split('_')[0];
};
export const isModGood = (value) => Boolean(String(value).split('_')[1]);

export const getNewPrice = (price, condition, value, persent) => {
  if (!price) return 0;
  if (condition === 'equal') return value;
  if (persent) {
    const persentPrice = Math.round(price * (value / 100));
    if (condition === 'plus') {
      return price + persentPrice;
    }
    return price - persentPrice;
  }
  if (condition === 'plus') {
    return price + value;
  }
  if (condition === 'multiply') {
    return price * value;
  }
  return price - value;
};

export const goodWithRegionPrices = (good, city) => {
  if (isEmpty(good.regionprices) || !city) {
    return good;
  }

  const regionprice = good.regionprices.find((item) => {
    if (!item.active) return good;

    const citiesIds = item.cities.map((cityItem) => cityItem.id);
    return citiesIds.includes(city.id);
  });
  if (!regionprice) return good;
  const {
    condition, price, oldPrice, persent,
  } = regionprice;

  const newPrice = getNewPrice(good.price, condition, price, persent);
  const newOldPrice = getNewPrice(good.oldPrice || 0, condition, oldPrice || 0, persent);

  return { ...good, price: newPrice, oldPrice: newOldPrice };
};

export const goodWithStopList = (good, city) => {
  if (!city) return good;
  if (good.disabledCities) {
    const dCities = good.disabledCities.split(',');
    if (dCities.includes(String(city.id))) {
      return { ...good, isStop: true };
    }
  }
  if (good.category.disabledCities) {
    const dCities = good.category.disabledCities.split(',');
    if (dCities.includes(String(city.id))) {
      return { ...good, isStop: true };
    }
  }
  return good;
};

export const goodWithDefaultMod = (good) => {
  if (!isEmpty(good.modifications)) {
    const defaultMod = good.modifications.find((item) => item.default) || good.modifications[0];
    if (defaultMod) {
      const id = getIdModGood(defaultMod, good.id);
      const modifiedGood = getModifiedGood(good, defaultMod.id);

      return {
        ...modifiedGood,
        id,
      };
    }
  }
  return good;
};
