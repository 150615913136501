import { loadersSelector } from './root';
import { propNameSelector } from '../utils';

export const globalLoaderSelector = propNameSelector(loadersSelector, 'global');
export const authLoaderSelector = propNameSelector(loadersSelector, 'auth');
export const goodsLoaderSelector = propNameSelector(loadersSelector, 'goods');
export const favoriteLoaderSelector = propNameSelector(loadersSelector, 'favorite');
export const cityLoaderSelector = propNameSelector(loadersSelector, 'city');
export const userLoaderSelector = propNameSelector(loadersSelector, 'user');
export const adressLoaderSelector = propNameSelector(loadersSelector, 'adress');
export const zoneLoaderSelector = propNameSelector(loadersSelector, 'zone');
export const promocodeLoaderSelector = propNameSelector(loadersSelector, 'promocode');
export const createOrderLoaderSelector = propNameSelector(loadersSelector, 'createOrder');
export const newsLoaderSelector = propNameSelector(loadersSelector, 'news');
export const notificationsLoaderSelector = propNameSelector(loadersSelector, 'notifications');
export const feedbackLoaderSelector = propNameSelector(loadersSelector, 'feedback');
export const userOrdersLoaderSelector = propNameSelector(loadersSelector, 'userOrders');
export const slidersLoaderSelector = propNameSelector(loadersSelector, 'sliders');
