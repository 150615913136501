import { combineReducers } from 'redux';
import delivery from './delivery';
import step from './step';
import customer from './customer';
import errors from './errors';
import prices from './prices';
import payment from './payment';
import message from './message';

export default combineReducers({
  customer,
  delivery,
  step,
  errors,
  prices,
  payment,
  message,
});
