import { cancelOrder, setOrderPaymentCash, setOrderPaymentMethod } from '../../../actions';
import { identityPayload, createReducer, forAction } from '../../../utils';

const initialState = 'no';

export default createReducer(
  [forAction(setOrderPaymentCash, identityPayload)],
  initialState,
  [cancelOrder, setOrderPaymentMethod],
);
