import React from 'react';
import { NotificationList, UserPageContent } from '../containers';

export default function NotificationsPage() {
  return (
    <UserPageContent title="Уведомления">
      <NotificationList />
    </UserPageContent>
  );
}
