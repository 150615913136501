import { cancelOrder, setOrderPaymentEmail, setOrderPaymentMethod } from '../../../actions';
import { identityPayload, createReducer, forAction } from '../../../utils';

const initialState = '';

export default createReducer(
  [forAction(setOrderPaymentEmail, identityPayload)],
  initialState,
  [cancelOrder, setOrderPaymentMethod],
);
