import React from 'react';
import PropTypes from 'prop-types';

export function Loader({ list, image, banner }) {
  if (banner) {
    return (
      <div className="d-flex align-items-center justify-content-center h-100 rounded border mw-100">
        <span className="spinner-border-md spinner-border text-primary-sushibox" role="status" />
      </div>
    );
  }

  if (image) {
    return (
      <div className="d-flex align-items-center justify-content-center min-h-150px mw-100">
        <span className="spinner-border-md spinner-border text-primary-sushibox" role="status" />
      </div>
    );
  }

  if (list) {
    return (
      <div className="sb-loading flex-column">
        <div className="d-flex align-items-center">
          <span className="spinner-border-md spinner-border text-primary-sushibox" role="status" />
        </div>
      </div>
    );
  }

  return (
    <div className="page-loader flex-column">
      <img alt="Logo" className="h-75px" src="/assets/media/logo.png" />
      <div className="d-flex align-items-center mt-10">
        <span className="spinner-border text-primary-sushibox" role="status" />
      </div>
    </div>
  );
}

Loader.defaultProps = {
  list: false,
  image: false,
  banner: false,
};

Loader.propTypes = {
  list: PropTypes.bool,
  image: PropTypes.bool,
  banner: PropTypes.bool,
};
