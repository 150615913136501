import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { fromStore } from '../../../selectors';
import { Icon, IconTypes } from '../../../components';
import { setOrderPaymentMethod, setActiveModalAction } from '../../../actions';

export function PaymentStepType({ availablePaymentMethod }) {
  const dispatch = useDispatch();
  const method = useSelector(fromStore.orderPaymentMethodSelector);
  const user = useSelector(fromStore.userSelector);

  const openAuth = () => {
    dispatch(setActiveModalAction({ field: 'auth', value: true }));
  };

  useEffect(() => {
    if (method === 'online' && !user) {
      openAuth();
    }
  }, [user]);

  const onChange = (e) => {
    dispatch(setOrderPaymentMethod(e.target.value));
    if (e.target.value === 'online' && !user) {
      openAuth();
    }
  };

  const renderItem = (item) => (
    <label htmlFor="inputradioform" className="d-flex flex-stack mb-3 cursor-pointer">
      <span className="d-flex align-items-center me-2">
        <span className="symbol symbol-40px me-6">
          <span className="symbol-label bg-gray-200">
            <span className={cn('svg-icon svg-icon-1', item.checked ? 'svg-icon-primary' : 'svg-icon-gray-600')}>
              <Icon type={IconTypes.payment} />
            </span>
          </span>
        </span>
        <span className="d-flex flex-column">
          <span className="fw-bold text-gray-800 fs-5">{item.label}</span>
        </span>
      </span>
      <span className="form-check form-check-custom form-check-solid">
        <input id="inputradioform" onChange={onChange} className="form-check-input" type="radio" value={item.value} checked={item.checked} />
      </span>
    </label>
  );

  return (
    <div className="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
      { availablePaymentMethod.map((item) => renderItem(item)) }
    </div>
  );
}

PaymentStepType.propTypes = {
  availablePaymentMethod: PropTypes.array.isRequired,
};
