import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fromStore } from '../../../selectors';
import { setSelectedCity } from '../../../actions';
import { CityItem } from './CityItem';
import { FilterCities } from './FilterCities';

export function CitiesList({ notCityList }) {
  const dispatch = useDispatch();
  const cities = useSelector(fromStore.filteredCitiesListSelector);
  const selected = useSelector(fromStore.selectedCitySelector);

  const onSelectCity = (city) => {
    dispatch(setSelectedCity(city));
  };

  if (!selected && !notCityList) return null;

  const emptyResult = (
    <div className="fw-bold py-10 text-center">
      <div className="text-gray-600 fs-3 mb-2">Город не найден</div>
      <div className="text-muted fs-6">Возможно мы пока не работаем в вашем городе</div>
    </div>
  );

  const renderCities = cities.length > 0 ? cities.map((city, index) => (
    <CityItem
      key={city.id}
      city={city}
      last={index !== cities.length - 1}
      selected={selected && selected.id === city.id}
      onSelectCity={onSelectCity}
    />
  )) : emptyResult;

  return (
    <>
      <FilterCities />
      <div className="mh-500px scroll-y me-n7 pe-7 border-top mt-5">
        {renderCities}
      </div>
    </>
  );
}

CitiesList.propTypes = {
  notCityList: PropTypes.bool.isRequired,
};
