import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearCart, closeCart } from '../../actions';
import { declOfNum } from '../../utils';
import { fromStore } from '../../selectors';

export function CartHeader() {
  const dispatch = useDispatch();

  const count = useSelector(fromStore.countPositionCartSelector);

  const onClose = () => dispatch(closeCart());
  const onTrash = () => dispatch(clearCart());

  return (
    <div className="card-header pe-5" style={{ minHeight: 'inherit' }}>
      <div className="card-title">
        <div className="d-flex justify-content-center flex-column me-3">
          <div className="fs-1 fw-bolder text-gray-900 text-hover-primary me-1 mt-3 mb-3 lh-1">Корзина</div>
          <div className="mb-3 lh-1">
            <span className="fs-5 fw-bold text-muted">
              <span className="badge badge-dark">{declOfNum(count, ['позиция', 'позиции', 'позиций'])}</span>
            </span>
          </div>
        </div>
      </div>

      <div className="card-toolbar">
        <div className="me-2">
          <button onClick={onTrash} className="btn btn-sm btn-icon btn-active-light-danger" type="button">
            <span className="svg-icon svg-icon-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor" />
                <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor" />
                <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor" />
              </svg>
            </span>
          </button>
        </div>

        <div aria-hidden onClick={onClose} className="btn btn-sm btn-icon btn-active-light-dark">
          <span className="svg-icon svg-icon-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
              <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
            </svg>
          </span>

        </div>
      </div>
    </div>
  );
}
