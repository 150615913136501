import * as loadersSelectors from './loaders';
import * as citiesSelectors from './cities';
import * as authSelectors from './auth';
import * as goodsSelectors from './goods';
import * as cartSelectors from './cart';
import * as entitiesSelectors from './entities';
import * as orderSelectors from './order';
import * as settingsSelectors from './settings';

export * from './root';

export const fromStore = {
  ...authSelectors,
  ...loadersSelectors,
  ...citiesSelectors,
  ...goodsSelectors,
  ...cartSelectors,
  ...entitiesSelectors,
  ...orderSelectors,
  ...settingsSelectors,
};
