import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fromStore } from '../../../selectors';
import { setActiveModalAction } from '../../../actions';

import { AutoCity } from './AutoCity';
import { CitiesList } from './CitiesList';
import { useOnClickOutside } from '../../../hooks';

export function CityModal() {
  const dispatch = useDispatch();
  const [notCityList, setNotCityList] = useState(false);
  const ref = useRef();
  const selected = useSelector(fromStore.selectedCitySelector);
  const loader = useSelector(fromStore.cityLoaderSelector);

  const onClose = () => {
    if (!loader) {
      dispatch(setActiveModalAction({ field: 'city', value: false }));
    }
  };

  const onSetNotCityList = () => {
    setNotCityList(true);
  };

  useOnClickOutside(ref, () => {
    if (selected) onClose();
  });

  const renderTitle = (
    <div className="text-center mb-10">
      <h1 className="mb-3">Выберите город</h1>
      <div className="text-muted fw-bold fs-5">
        Для оформления заказа необходимо выбрать город
      </div>
    </div>
  );

  const renderCloseButton = selected ? (
    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={onClose} aria-hidden>
      <span className="svg-icon svg-icon-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
          <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
        </svg>
      </span>
    </div>
  ) : null;

  return (
    <div className="modal fade d-block show">
      <div className="modal-dialog mw-650px">
        <div ref={ref} className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            {renderCloseButton}
          </div>
          <div className="modal-body scroll-y mx-5 mx-xl-5 pt-0 pb-10">
            {renderTitle}
            <AutoCity notCityList={notCityList} onSetNotCityList={onSetNotCityList} />
            <CitiesList notCityList={notCityList} />
          </div>
        </div>
      </div>
    </div>
  );
}
