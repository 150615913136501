import React from 'react';
import { ProfileCard, UserPageContent } from '../containers';

export default function UserPage() {
  return (
    <UserPageContent title="Мой профиль">
      <ProfileCard />
    </UserPageContent>
  );
}
