/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotifications } from '../actions';
import { Loader } from '../components';
import { DATE_FORMAT } from '../constants';
import { fromStore, notificationsSelector } from '../selectors';

export function NotificationList() {
  const dispatch = useDispatch();
  const loader = useSelector(fromStore.notificationsLoaderSelector);
  const notifications = useSelector(notificationsSelector);

  useEffect(() => {
    if (notifications.length === 0) {
      dispatch(fetchNotifications());
    }
  }, []);

  if (loader) return <Loader list />;

  const emptyResult = (
    <div className="fw-bold">
      <div className="text-gray-700 fs-3 mb-2">Уведомлений нет</div>
    </div>
  );

  const renderItem = (item) => (
    <div key={`notification_${item.id}`} className="d-flex flex-stack position-relative mt-10">
      <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
      <div className="fw-bold ms-5 text-gray-600">
        <div className="fs-6 mb-1">
          {dayjs(item.created_at).format(DATE_FORMAT)}
        </div>
        <div className="fs-5 fw-bolder text-gray-800 mb-3">{item.theme}</div>
        <div className="text-gray-800">
          {item.message}
        </div>
      </div>
    </div>
  );

  return (
    <div className="post abs">
      <div className="row g-6 g-xl-9">
        <div className="col-lg-12 mt-0">
          <div className="card border-0 card-flush h-lg-100">
            <div className="card-body p-0 pb-20">
              {isEmpty(notifications) ? emptyResult : notifications.map(renderItem)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
