import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';
import { addAdressAction, setActiveModalAction } from '../../../actions';
import { useOnClickOutside } from '../../../hooks';
import { Checkbox, Input } from '../../../components';
import { fromStore } from '../../../selectors';
import { adressFields } from '../../../constants';

const getDefaultValues = (user) => {
  const fields = {};
  Object.entries(adressFields).forEach(([field, values]) => {
    fields[field] = get(user, field, values.defaultValue);
  });
  return fields;
};

export function AdressModal() {
  const dispatch = useDispatch();
  const ref = useRef();
  const user = useSelector(fromStore.userSelector);
  const orderAdress = useSelector(fromStore.orderDeliveryAdressSelector);
  const loader = useSelector(fromStore.adressLoaderSelector);
  const form = useForm({ defaultValues: getDefaultValues(user || orderAdress) });
  const {
    register, setValue, watch, handleSubmit, unregister, formState: { errors },
  } = form;
  const {
    street, privateHouse, house, room, entrance, floor, area,
  } = watch();

  const onClose = () => {
    if (loader) return;
    dispatch(setActiveModalAction({ field: 'adress', value: false }));
  };
  const handleSetValue = (field) => (value) => {
    setValue(field, value);
  };

  const handleSetChecked = (field) => (value) => {
    setValue(field, value);
  };

  const onSubmit = (data) => {
    dispatch(addAdressAction(data));
  };

  useOnClickOutside(ref, onClose);

  useEffect(() => {
    if (privateHouse) {
      Object.entries(adressFields).forEach(([field, value]) => {
        if (value.disableValidationFormPrivate) {
          unregister(field);
        }
      });
    } else {
      Object.entries(adressFields).forEach(([field, value]) => register(field, value.validation));
    }
  }, [privateHouse]);

  const renderCloseButton = (
    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={onClose} aria-hidden>
      <span className="svg-icon svg-icon-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
          <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
        </svg>
      </span>
    </div>
  );

  const renderButtons = (
    <div className="text-center">
      <button onClick={onClose} disabled={loader} type="button" className="btn btn-light me-3">Отмена</button>
      <button disabled={loader} type="submit" className="btn btn-primary">
        {loader ? (
          <span className="indicator-progress d-block">
            Сохранение...
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          </span>
        ) : (
          <span className="indicator-label">Сохранить</span>
        )}
      </button>
    </div>
  );

  return (
    <div className="modal fade d-block show">
      <div className="modal-dialog modal-lg mw-650px">
        <div ref={ref} className="modal-content rounded">
          <div className="modal-header pb-0 border-0 justify-content-end">
            {renderCloseButton}
          </div>
          <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
            <form onSubmit={handleSubmit(onSubmit)} className="form fv-plugins-bootstrap5 fv-plugins-framework">
              <div className="mb-13 text-center">
                <h1 className="mb-3">Адрес доставки</h1>
              </div>

              <div className="d-flex flex-stack mb-8">
                <Checkbox
                  id="cb_private"
                  title={adressFields.privateHouse.title}
                  checked={privateHouse}
                  onChange={handleSetChecked('privateHouse')}
                />
              </div>

              <Input
                title={adressFields.street.title}
                placeholder="Введите название улицы"
                minLength={adressFields.street.validation.minLength}
                maxLength={adressFields.street.validation.maxLength}
                onChange={handleSetValue('street')}
                value={street}
                error={get(errors, 'street.message')}
                required
                disabled={loader}
              />

              <div className="row g-9 mb-5">
                <Input
                  title={adressFields.house.title}
                  className="col-md-6"
                  placeholder="Введите номер дома"
                  minLength={adressFields.house.validation.minLength}
                  maxLength={adressFields.house.validation.maxLength}
                  onChange={handleSetValue('house')}
                  value={house}
                  error={get(errors, 'house.message')}
                  required
                  disabled={loader}
                />
                {!privateHouse && (
                  <Input
                    title={adressFields.room.title}
                    className="col-md-6"
                    placeholder="Введите номер квартиры"
                    minLength={adressFields.room.validation.minLength}
                    maxLength={adressFields.room.validation.maxLength}
                    onChange={handleSetValue('room')}
                    value={room}
                    error={get(errors, 'room.message')}
                    required
                    disabled={loader}
                  />
                )}
              </div>

              {!privateHouse && (
                <div className="row g-9 mb-5">
                  <Input
                    title={adressFields.floor.title}
                    className="col-md-6"
                    placeholder="Введите номер этажа"
                    minLength={adressFields.floor.validation.minLength}
                    maxLength={adressFields.floor.validation.maxLength}
                    onChange={handleSetValue('floor')}
                    value={floor}
                    error={get(errors, 'floor.message')}
                    required
                    disabled={loader}
                  />

                  <Input
                    title={adressFields.entrance.title}
                    className="col-md-6"
                    placeholder="Введите номер подъезда"
                    minLength={adressFields.entrance.validation.minLength}
                    maxLength={adressFields.entrance.validation.maxLength}
                    onChange={handleSetValue('entrance')}
                    value={entrance}
                    error={get(errors, 'entrance.message')}
                    required
                    disabled={loader}
                  />
                </div>
              )}

              <Input
                title={adressFields.area.title}
                placeholder="Введите название района"
                minLength={adressFields.area.validation.minLength}
                maxLength={adressFields.area.validation.maxLength}
                onChange={handleSetValue('area')}
                value={area}
                error={get(errors, 'area.message')}
                disabled={loader}
              />

              {renderButtons}
              <div />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
