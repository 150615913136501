import { openCart, clearCart, closeCart } from '../../actions';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = false;

export default createReducer(
  [forAction(openCart, identityPayload)],
  initialState,
  [clearCart, closeCart],
);
