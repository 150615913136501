import React from 'react';
import { useSelector } from 'react-redux';
import { fromStore } from '../../selectors';

import { Footer } from './Footer';
import { Aside } from './Aside';
import { steps } from './config';

export function OrderStepper() {
  const activeStep = useSelector(fromStore.orderStepSelector);
  const cartCount = useSelector(fromStore.totalGoodsCartSelector);
  const Component = steps[activeStep].component;

  if (!cartCount) {
    return (
      <div className="fw-bold">
        <div className="text-gray-700 fs-3 mb-2">Нет товаров для оформления заказа</div>
      </div>
    );
  }

  return (
    <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid">
      <Aside />
      <div className="card d-flex flex-row-fluid flex-center">
        <div className="card-body py-20 w-100 w-xl-700px px-9">
          <Component />
          <Footer />
        </div>
      </div>
    </div>
  );
}
