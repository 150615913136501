import { createAction } from 'redux-actions';
import { makeActionType } from '../utils';

const namespace = 'CITIES';

export const fetchCities = createAction(makeActionType(namespace, ['FETCH']));

export const setCities = createAction(makeActionType(namespace, ['SET']));

export const setZones = createAction(makeActionType(namespace, ['SET', 'ZONES']));

export const setSelectedCity = createAction(makeActionType(namespace, ['SET', 'SELECTED_CITY']));

export const setQueryCity = createAction(makeActionType(namespace, ['SET', 'QUERY']));

export const fetchZone = createAction(makeActionType(namespace, ['FETCH', 'ZONE']));

export const fetchZones = createAction(makeActionType(namespace, ['FETCH', 'ZONES']));
