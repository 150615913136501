import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveModalAction } from '../../../../actions';
import { Icon, IconTypes } from '../../../../components';
import { fromStore } from '../../../../selectors';
import { getAdress } from '../../../../utils';
import { DeliveryZone } from './DeliveryZone';

export function Adress() {
  const dispatch = useDispatch();
  const adress = useSelector(fromStore.orderDeliveryAdressSelector);
  const city = useSelector(fromStore.selectedCitySelector);
  const onAdressModal = () => {
    dispatch(setActiveModalAction({ field: 'adress', value: true }));
  };

  return (
    <div>
      <h3 className="mb-5 required">Адрес доставки</h3>
      {adress.street ? (
        <div className="col-lg-12 d-flex align-items-center border border-secondary rounded ps-5 pe-1 py-1 flex-stack">
          <span className="fw-bolder fs-5 text-gray-800">{getAdress(adress, city.name)}</span>
          <button onClick={onAdressModal} type="button" className="btn btn-sm btn-icon btn-light btn-active-white ms-1 btn-hover-scale">
            <Icon type={IconTypes.edit} className="svg-icon svg-icon-3" />
          </button>
        </div>
      ) : (
        <div className="col-lg-12 d-flex align-items-center">
          <button onClick={onAdressModal} type="button" className="btn btn-danger btn-sm py-2 btn-hover-rise">Добавить адрес доставки</button>
        </div>
      )}
      {adress.street && <DeliveryZone />}
    </div>
  );
}
