import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export function PizzaSizer({ items, selected, onChange }) {
  const onClick = (val) => (e) => {
    e.stopPropagation();
    onChange(val);
  };

  return (
    <div className="nav d-inline-flex bg-light rounded-pill px-3 py-2">
      {items.map((item) => (
        <div
          key={item.id}
          aria-hidden
          onClick={onClick(item)}
          className={cn('nav-link btn btn-active btn-active-dark fw-bolder btn-color-gray-600 py-3 px-5 m-1 rounded-pill', { active: item.id === selected.id })}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
}

PizzaSizer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
