import { cancelOrder, setOrderDeliveryMethod, setOrderErrors } from '../../actions';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = {};

export default createReducer(
  [forAction(setOrderErrors, identityPayload)],
  initialState,
  [cancelOrder, setOrderDeliveryMethod],
);
