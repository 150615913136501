import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setAside } from '../actions';

export function Logo() {
  const dispatch = useDispatch();

  const onClickNav = () => {
    dispatch(setAside(true));
  };

  return (
    <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 w-lg-250px me-5">
      <div aria-hidden onClick={onClickNav} className="btn btn-icon btn-active-icon-primary ms-n2 me-2 d-flex d-lg-none">
        <span className="svg-icon svg-icon-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
            <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
          </svg>
        </span>
      </div>
      <Link to="/">
        <img alt="SUSHIBOX" src="/assets/media/logo.png" className="d-none d-lg-inline h-50px" />
        <img alt="SUSHIBOX" src="/assets/media/logo.png" className="d-lg-none h-20px" />
      </Link>
    </div>
  );
}
