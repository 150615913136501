import React from 'react';
import { useSelector } from 'react-redux';
import { fromStore } from '../../selectors';
import { CartGood } from './CartGood';
import { CartWok } from './CartWok';

export function CartBody() {
  const goods = useSelector(fromStore.cartGoodsSelector);
  const woks = useSelector(fromStore.wokListCartSelector);
  const gift = useSelector(fromStore.giftCartSelector);
  const renderGoods = goods.map((item) => <CartGood key={item.id} item={item} />);
  const renderWoks = Object.values(woks).map((item) => <CartWok key={item.id} item={item} />);

  return (
    <div className="card-body scroll-y">
      <div className="me-n5 pe-5">
        {renderGoods}
        {renderWoks}
        {gift && <CartGood gift key={`gift_${gift.id}`} item={gift} />}
      </div>
    </div>
  );
}
