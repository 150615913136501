import React, { useEffect, useState } from 'react';
import Carousel, { Dots, autoplayPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { fromStore } from '../selectors';
import { CartGood } from './cart/CartGood';
import { deleteGiftFromCart } from '../actions';

export function GiftGoods() {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const goods = useSelector(fromStore.giftGoodsSelector);
  const isActive = useSelector(fromStore.activeCartSelector);
  const isActiveGift = useSelector(fromStore.isActiveGift);
  const gift = useSelector(fromStore.giftCartSelector);

  useEffect(() => {
    if (isActive) {
      setValue(0);
    }
  }, [isActive]);

  useEffect(() => {
    if (gift && !isActiveGift) {
      dispatch(deleteGiftFromCart());
    }
  }, [gift, isActiveGift]);

  if (!isActive || isEmpty(goods) || !isActiveGift) return null;

  const slides = goods.map((item) => <CartGood gift className="d-flex flex-stack w-100" item={item} />);

  const onChange = (valueItem) => {
    setValue(valueItem);
  };

  return (
    <div className="py-5 border-bottom">
      <div className="d-flex flex-stack mb-5">
        <div className="fs-3 fw-bold">Выберите подарок</div>
        <Dots
          className="custom-dot"
          number={slides.length}
          value={value}
          onChange={onChange}
        />
      </div>
      <div style={{ display: 'grid' }}>
        <Carousel
          value={value}
          slides={slides}
          onChange={onChange}
          plugins={[
            {
              resolve: autoplayPlugin,
              options: {
                interval: 10000,
              },
            },
          ]}
          animationSpeed={500}
        />
      </div>
    </div>
  );
}
