/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { sendFeedback, successFeedback } from '../actions';
import { Input } from '../components';
import { feedbackFields } from '../constants';
import { fromStore, isSuccessFeedbackSelector } from '../selectors';

const getDefaultValues = () => {
  const fields = {};
  Object.entries(feedbackFields).forEach(([field, values]) => {
    fields[field] = values.defaultValue;
  });
  return fields;
};

export function FeedbackForm() {
  const dispatch = useDispatch();
  const loader = useSelector(fromStore.feedbackLoaderSelector);
  const isSuccess = useSelector(isSuccessFeedbackSelector);
  const form = useForm({ defaultValues: getDefaultValues() });
  const {
    register, setValue, watch, handleSubmit, unregister, formState: { errors }, reset,
  } = form;
  const { name, email, message } = watch();

  const handleSetValue = (field) => (value) => {
    setValue(field, value);
  };

  const handleSetMessageValue = (e) => {
    setValue('message', e.target.value);
  };

  const onSubmit = (data) => {
    dispatch(sendFeedback(data));
  };

  useEffect(() => {
    Object.entries(feedbackFields).forEach(([field, value]) => register(field, value.validation));
    return () => unregister(Object.keys(feedbackFields));
  }, []);

  useEffect(() => {
    if (isSuccess) {
      reset();
      dispatch(successFeedback(false));
    }
  }, [isSuccess]);

  const renderButton = (
    <button type="submit" className="btn btn-primary">
      {loader ? (
        <span className="indicator-progress d-block">
          Отправляем...
          <span className="spinner-border spinner-border-sm align-middle ms-2" />
        </span>
      ) : (
        <span className="indicator-label">Отправить</span>
      )}
    </button>
  );

  const renderTextArea = () => {
    const error = get(errors, 'message.message');

    return (
      <div className="d-flex flex-column mb-10">
        <label className="fs-6 fw-bold mb-2 required">{feedbackFields.message.title}</label>
        <textarea
          onChange={handleSetMessageValue}
          value={message}
          className="form-control form-control-solid"
          rows="6"
          placeholder="Текст сообщения..."
          minLength={feedbackFields.message.validation.minLength}
          maxLength={feedbackFields.message.validation.maxLength}
        />
        {error && <div className="invalid-feedback d-block">{error}</div>}
      </div>
    );
  };

  return (
    <div className="card mb-10 mt-10">
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 pe-lg-10">
            <form onSubmit={handleSubmit(onSubmit)} className="form">
              <h1 className="fw-bolder text-dark mb-9">Обратная связь</h1>

              <div className="row mb-5">
                <div className="col-md-6">
                  <Input
                    title={feedbackFields.name.title}
                    placeholder="Введите имя..."
                    minLength={feedbackFields.name.validation.minLength}
                    maxLength={feedbackFields.name.validation.maxLength}
                    onChange={handleSetValue('name')}
                    value={name}
                    error={get(errors, 'name.message')}
                    required
                    disabled={loader}
                  />
                </div>

                <div className="col-md-6">
                  <Input
                    title={feedbackFields.email.title}
                    placeholder="Введите e-mail..."
                    minLength={feedbackFields.email.validation.minLength}
                    maxLength={feedbackFields.email.validation.maxLength}
                    onChange={handleSetValue('email')}
                    value={email}
                    error={get(errors, 'email.message')}
                    required
                    disabled={loader}
                  />
                </div>
              </div>
              {renderTextArea()}
              {renderButton}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
