import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export function Input({
  title, minLength, maxLength, placeholder, value, onChange, error, required, className, disabled,
  readonly,
}) {
  const onChangeValue = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={className || 'd-flex flex-column mb-5'}>
      {title && (
      <div className="d-flex align-items-center fs-6 fw-bold mb-2">
        <span className={cn({ required })}>{title}</span>
      </div>
      )}
      <input
        readOnly={readonly}
        minLength={minLength}
        maxLength={maxLength}
        value={value || ''}
        onChange={onChangeValue}
        type="text"
        className="form-control form-control-solid"
        placeholder={placeholder}
        disabled={disabled}
      />
      {
      error && <div className="invalid-feedback d-block">{error}</div>
    }
    </div>

  );
}

Input.defaultProps = {
  title: null,
  minLength: null,
  maxLength: null,
  placeholder: '',
  error: null,
  required: false,
  className: '',
  disabled: false,
  readonly: false,
  value: null,
};

Input.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
};
