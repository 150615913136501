import { combineReducers } from 'redux';
import name from './name';
import unit from './unit';
import phone from './phone';

export default combineReducers({
  name,
  unit,
  phone,
});
