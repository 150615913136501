import { combineReducers } from 'redux';
import list from './list';
import active from './active';
import canClose from './canClose';
import gift from './gift';
import wokList from './wokList';

export default combineReducers({
  active,
  list,
  canClose,
  gift,
  wokList,
});
