import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { asideSelector, fromStore } from '../selectors';
import { useOnClickOutside } from '../hooks';
import { setAside } from '../actions';

export function AsideMenu() {
  const dispatch = useDispatch();
  const ref = useRef();
  const active = useSelector(asideSelector);
  const categories = useSelector(fromStore.denormalizedCategories);
  const goods = useSelector(fromStore.groupedGoodsList);
  const availableCategories = categories.filter((category) => goods[category.id]);

  useOnClickOutside(ref, (e) => {
    if (active) {
      e.stopImmediatePropagation();
      dispatch(setAside(false));
    }
  });

  return (
    <>
      <div ref={ref} className={cn('aside drawer drawer-start', { 'drawer-on': active })}>
        <div className="hover-scroll-overlay-y my-5 my-lg-5 w-100 ps-4 ps-lg-0 pe-4 me-1">
          <div className="menu menu-column menu-active-bg menu-hover-bg menu-title-gray-700 fs-6 menu-rounded w-100">
            {availableCategories.map((category) => (
              <div key={category.key} className="menu-item">
                <NavLink onClick={() => dispatch(setAside(false))} to={`/menu/${category.key}`} className="menu-link">
                  <span className="menu-title">{category.title}</span>
                  <span className="menu-badge">{goods[category.id].length}</span>
                </NavLink>
              </div>
            ))}

          </div>

        </div>
      </div>
      {active && <div style={{ zIndex: 109 }} className="drawer-overlay" />}
    </>
  );
}
