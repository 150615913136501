import React from 'react';
import PropTypes from 'prop-types';
import { GoodsList } from './GoodsList';
import { OrderInfo } from './OrderInfo';

export function OrderDetail({ order }) {
  return (
    <div className="post abs">
      <div className="d-flex flex-column gap-7 gap-lg-10">
        <OrderInfo order={order} />
        <div className="d-flex flex-column gap-7 gap-lg-10">
          <div className="card card-flush py-4 flex-row-fluid overflow-hidden border">
            <div className="card-body pt-0">
              <GoodsList order={order} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

OrderDetail.propTypes = {
  order: PropTypes.object.isRequired,
};
