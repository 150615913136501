import { loaderAction } from '../actions';
import { createReducer, forAction } from '../utils';

const initialState = {
  global: true,
  auth: false,
  goods: true,
  favorite: null,
  city: false,
  user: false,
  adress: false,
  zone: false,
  promocode: false,
  createOrder: false,
  news: true,
  sliders: true,
  notifications: true,
  feedback: false,
  userOrders: true,
  zones: true,
};

export default createReducer(
  [forAction(loaderAction, (state, { payload: { field, value } }) => ({
    ...state,
    [field]: value,
  }))],
  initialState,
);
