import { setCanCloseCart, clearCart, closeCart } from '../../actions';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = true;

export default createReducer(
  [forAction(setCanCloseCart, identityPayload)],
  initialState,
  [clearCart, closeCart],
);
