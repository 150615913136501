import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchNews, loaderAction, setNews } from '../actions';
import { restUrls } from '../constants';
import { request } from '../utils';

function* fetchNewsHandler() {
  yield put(loaderAction({ field: 'news', value: true }));
  const news = yield call(request, { method: 'get', url: `${restUrls.news}?_sort=created_at:desc` }, false);
  yield put(setNews(news));
  yield put(loaderAction({ field: 'news', value: false }));
}

export default [
  takeLatest(fetchNews, fetchNewsHandler),
];
