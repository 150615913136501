import { combineReducers } from 'redux';
import list from './list';
import selected from './selected';
import query from './query';
import selectedZones from './selectedZones';

export default combineReducers({
  list,
  selected,
  query,
  selectedZones,
});
