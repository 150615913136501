import { isEmpty, cloneDeep } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  YMaps, Map, Placemark, Polygon,
} from 'react-yandex-maps';
import { Icon, IconTypes } from '../components';
import { fromStore } from '../selectors';
import { getAdress } from '../utils';
import { FeedbackForm } from './FeedbackForm';
import { fetchZones } from '../actions';

const getCoorditates = (coord) => coord.split(',');

const reverseCoord = (coors) => {
  const clone = cloneDeep(coors);
  const result = clone[0].map((item) => item.reverse());
  return [result];
};

const colours = [
  '#f9c74f', '#90be6d', '#43aa8b', '#4d908e', '#577590', '#277da1', '#1d2f6f', '#8390fa', '#fac748', '#f88dad', '#1982c4',
  '#f94144', '#f3722c', '#f8961e', '#f9844a', '#033f63', '#28666e', '#7c9885', '#b5b682', '#fedc97', '#32cbff', '#00a5e0', '#89a1ef', '#ef9cda',
  '#006ba6', '#0496ff', '#d81159', '#8f2d56', '#dad7cd', '#a3b18a', '#3a5a40', '#d90429', '#9b5de5', '#f15bb5',
];

export function ContactsList() {
  const dispatch = useDispatch();
  const city = useSelector(fromStore.selectedCitySelector);
  const selectedZones = useSelector(fromStore.selectedZonesSelector);
  const cityСoordinates = getCoorditates(city.coord);
  const cityPoints = city.points || [];

  const mapCoordinates = cityСoordinates;

  const renderCityPoint = (point) => (
    <div key={`point_${point.id}`} className="col-xl-4 col-md-6 d-flex flex-stack text-start mb-10">
      <div className="d-flex align-items-start">
        <div className="me-5">
          <Icon className="svg-icon svg-icon-2x svg-icon-primary" type={IconTypes.shop} />
        </div>
        <div className="flex-grow-1">
          <h2 className="d-flex align-items-center fs-5 fw-bolder flex-wrap mb-5">{getAdress(point)}</h2>
          <div className="fw-bold opacity-50 mb-1">{`Телефон: ${point.phone}`}</div>
          <div className="fw-bold opacity-50">{`E-mail: ${point.email}`}</div>
          {point.legalName && <div className="fw-bold opacity-50">{`ИП: ${point.legalName}`}</div>}
          {point.inn && <div className="fw-bold opacity-50">{`ИНН: ${point.inn}`}</div>}
          {point.ogrnip && <div className="fw-bold opacity-50">{`ОГРНИП: ${point.ogrnip}`}</div>}
          {point.legalAdress && <div className="fw-bold opacity-50">{`Почтовый адрес: ${point.legalAdress}`}</div>}
          <div className="fw-bold opacity-75 mt-3">{`Работаем: ${point.timeWork}`}</div>
        </div>
      </div>
    </div>
  );

  const templatePlacemark = (point) => `
        <div>
            <div>Телефон: <b>${point.phone}</b></div>
            <div>E-mail: <b>${point.email}</b></div>
            ${point.legalName ? `<div>ИП: <b>${point.legalName}</b></div>` : ''}
            ${point.inn ? `<div>ИНН: <b>${point.inn}</b></div>` : ''}
            ${point.ogrnip ? `<div>ОГРНИП: <b>${point.ogrnip}</b></div>` : ''}
            ${point.legalAdress ? `<div>Почтовый адрес: <b>${point.legalAdress}</b></div>` : ''}
            <div>Работаем: <b>${point.timeWork}</b></div>
        </div>
      `;

  const templateZonePlacemark = (zone) => {
    const defaultTime = zone.point && zone.point.minDeliveryTime ? zone.point.minDeliveryTime : 60;

    const time = defaultTime + zone.deliveryTime;
    const price = zone.deliveryPrice || 0;
    const minOrder = zone.minDeliveryOrder;

    return `
      <div>
          <div>Мин. сумма заказа: <b>${minOrder} ₸</b></div>
          <div>Срок доставки: <b>${time} мин.</b></div>
          <div>Стоимость доставки: <b>${price > 0 ? `${price} ₸` : 'Бесплатно'} </b></div>
      </div>
    `;
  };

  const emptyResult = (
    <div className="fw-bold">
      <div className="text-gray-700 fs-3 mb-2">Нет доступных магазинов</div>
    </div>
  );

  useEffect(() => {
    if (city.id) {
      dispatch(fetchZones(city.id));
    }
  }, [city.id]);

  return (
    <div className="post abs pt-0">
      <div className="card border-0">
        <div className="card-body p-0">
          <div className="d-flex flex-column w-100 rounded">
            <YMaps>
              <Map width="100%" height="500px" state={{ center: mapCoordinates, zoom: 12 }}>
                {selectedZones.map((zone, index) => {
                  const color = colours[index];
                  return (
                    <Polygon
                      key={zone.id}
                      geometry={reverseCoord(zone.place)}
                      options={{
                        fillColor: color,
                        fillOpacity: 0.6,
                        strokeColor: color,
                        strokeWidth: '1',
                        strokeOpacity: 0.9,
                      }}
                      properties={{
                        hintContent: templateZonePlacemark(zone),
                      }}
                    />
                  );
                })}
                {cityPoints.map((point) => (
                  <Placemark
                    key={`placemark_${point.id}`}
                    properties={{
                      balloonContentHeader: getAdress(point),
                      balloonContent: templatePlacemark(point),
                    }}
                    geometry={getCoorditates(point.coord)}
                    modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                  />
                ))}
              </Map>
            </YMaps>
          </div>
          <div className="mt-10">
            <h1>{city.name}</h1>
            <div className="row mt-10">
              { isEmpty(cityPoints) ? emptyResult : cityPoints.map(renderCityPoint)}
            </div>
          </div>
        </div>
      </div>
      <FeedbackForm />
    </div>
  );
}
