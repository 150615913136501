import {
  takeLatest, put, call,
} from 'redux-saga/effects';
import { successFeedback, loaderAction, sendFeedback } from '../actions';
import { showNotification, request } from '../utils';
import { restUrls } from '../constants';

function* sendFeedbackHandler({ payload: data }) {
  yield put(loaderAction({ field: 'feedback', value: true }));
  const result = yield call(request, { method: 'post', url: `${restUrls.sendMail}`, data }, false, {}, true);

  if (result.error === 'needRefresh') {
    yield put(sendFeedback(data));
    yield put(loaderAction({ field: 'feedback', value: false }));
    return;
  }

  if (result === 'sended') {
    yield put(successFeedback(true));
    yield put(loaderAction({ field: 'feedback', value: false }));
    yield showNotification({
      text: 'Соообщение отправлено',
      icon: 'success',
    });
  } else {
    yield put(successFeedback(false));
    yield put(loaderAction({ field: 'feedback', value: false }));
    yield showNotification({
      text: 'Ошибка при отправке сообщения',
      icon: 'error',
    });
  }
}

export default [
  takeLatest(sendFeedback, sendFeedbackHandler),
];
