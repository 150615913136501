import {
  addToCart, clearCart, deleteAllFromCart, deleteFromCart,
} from '../../actions';
import { createReducer, forAction } from '../../utils';

const initialState = {};

const addToCartHandler = (state, { payload: id }) => {
  if (state[id]) {
    return ({
      ...state,
      [id]: state[id] + 1,
    });
  }
  return ({
    ...state,
    [id]: 1,
  });
};

const deleteFromCartHandler = (state, { payload: id }) => {
  if (state[id] === 1) {
    const updated = { ...state };
    delete updated[id];
    return updated;
  }
  return ({
    ...state,
    [id]: state[id] - 1,
  });
};

const deleteAllFromCartHandler = (state, { payload: id }) => {
  const updated = { ...state };
  delete updated[id];
  return updated;
};

export default createReducer(
  [
    forAction(addToCart, addToCartHandler),
    forAction(deleteFromCart, deleteFromCartHandler),
    forAction(deleteAllFromCart, deleteAllFromCartHandler),
  ],
  initialState,
  [clearCart],
);
