import React from 'react';
import PropTypes from 'prop-types';
import { numberDevider } from '../../../../utils';

export function WokItem({ item }) {
  const {
    price, count, main, souce, meat, toppings,
  } = item;

  const getTitle = () => {
    const title = [main.title, 'овощи'];
    souce.forEach((sou) => title.push(`Соус ${sou.title}`));
    Object.values(meat).forEach((mea) => title.push(`${mea.title} x ${mea.count}`));
    Object.values(toppings).forEach((top) => title.push(`${top.title} x ${top.count}`));
    return title.join(', ').toLocaleLowerCase();
  };

  return (
    <tr>
      <td>
        <div aria-hidden className="d-flex align-items-center cursor-pointer">
          <div>
            <img className="w-50px h-50px rounded" src="/assets/media/wokk.png" alt="Мой Wok" />
          </div>
          <div className="ms-5">
            <div className="fw-bolder text-gray-600 text-hover-primary">Мой Wok</div>
            <div className="fs-7 text-muted  d-block">
              {getTitle()}
            </div>
          </div>
        </div>
      </td>
      <td className="text-end">{count}</td>
      <td className="text-end">{`${numberDevider(price)} ₸`}</td>
      <td className="text-end">{`${numberDevider(price * count)} ₸`}</td>
    </tr>
  );
}

WokItem.propTypes = {
  item: PropTypes.object.isRequired,
};
