import React from 'react';
import { useSelector } from 'react-redux';
import { modalsSelector } from '../../selectors';
import { AdressModal } from './AdressModal';
import { AuthModal } from './AuthModal';
import { CityModal } from './CityModal';
import { DetailModal } from './DetailModal';
import { PointModal } from './PointModal';
import { WokModal } from './WokModal/WokModal';

const modals = {
  auth: <AuthModal key="auth_modal" />,
  city: <CityModal key="city_modal" />,
  detail: <DetailModal key="detail_modal" />,
  adress: <AdressModal key="adress_modal" />,
  point: <PointModal key="point_modal" />,
  wok: <WokModal key="wok_modal" />,
};

export function Modals() {
  const stateModals = useSelector(modalsSelector);

  if (Object.keys(stateModals).some((key) => stateModals[key])) {
    return (
      <>
        {Object.keys(modals).filter((key) => stateModals[key]).map((key) => modals[key])}
        <div className="modal-backdrop fade show" />
      </>
    );
  }

  return null;
}
