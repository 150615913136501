import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { goodsSelector, modalsSelector } from './root';
import {
  propNameSelector,
  denormalizeItems, getModifiedGood, goodWithRegionPrices, goodWithStopList, goodWithDefaultMod,
} from '../utils';
import { categoriesEntitiesSelector, goodsEntitiesSelector } from './entities';
import { cartListSelector, giftCartSelector, wokListCartSelector } from './cart';
import { selectedCitySelector } from './cities';
import { getCategorySettingsId } from './settings';

export const goodsListSelector = propNameSelector(goodsSelector, 'list');
export const categoriesListSelector = propNameSelector(goodsSelector, 'categories');
export const detailGoodModal = propNameSelector(modalsSelector, 'detail');
const orderDeliveryPointSelector = (state) => state.order.delivery.point;
const orderDeliveryZoneDataSelector = (state) => state.order.delivery.zoneData;
const orderDeliveryMethodSelector = (state) => state.order.delivery.method;

const entityModifiedGoods = createSelector(
  goodsListSelector,
  goodsEntitiesSelector,
  selectedCitySelector,
  (ids, goods, city) => {
    const modifiedGoods = { ...goods };
    ids.forEach((id) => {
      let good = goodWithRegionPrices(modifiedGoods[id], city);
      good = goodWithStopList(good, city);
      good = goodWithDefaultMod(good);
      modifiedGoods[id] = good;
    });
    return modifiedGoods;
  },
);

const denormalizedGoods = createSelector(
  goodsListSelector,
  entityModifiedGoods,
  (ids, entities) => denormalizeItems(ids, 'goods', entities),
);

export const denormalizedCategories = createSelector(
  categoriesListSelector,
  categoriesEntitiesSelector,
  (ids, entities) => denormalizeItems(ids, 'categories', entities),
);

export const groupedGoodsList = createSelector(
  denormalizedGoods,
  (goods) => goods.filter((item) => !item.isStop).reduce((acc, item) => {
    const updated = { ...acc };
    const category = String(item.category.id);
    const id = String(item.id);
    if (updated[category]) {
      updated[category].push(id);
    } else {
      updated[category] = [id];
    }
    return updated;
  }, {}),
);

export const goodsByIdSelector = (id) => createSelector(
  entityModifiedGoods,
  (goods) => goods[id],
);

export const categoryByIdSelector = (id) => createSelector(
  categoriesEntitiesSelector,
  (category) => category[id],
);

const getCurrentPoint = (method, point, zone) => {
  if (method === 'own') {
    return point;
  }

  if (zone && zone.point) {
    return zone.point;
  }

  return null;
};

export const cartGoodsSelector = createSelector(
  cartListSelector,
  entityModifiedGoods,
  orderDeliveryMethodSelector,
  orderDeliveryPointSelector,
  orderDeliveryZoneDataSelector,
  (cartGoods, entities, method, point, zone) => {
    if (isEmpty(entities)) {
      return [];
    }

    const currentPoint = getCurrentPoint(method, point, zone);
    const stopList = currentPoint && !isEmpty(currentPoint.stopList)
      ? currentPoint.stopList.map((item) => +item.value)
      : [];

    return Object.entries(cartGoods).filter(([key]) => {
      const [id] = key.split('_');
      if (entities[id]) return true;
      return false;
    }).map(([key, count]) => {
      const [id, idMod] = key.split('_');
      const good = entities[id];

      const isStopPoint = good.article ? stopList.includes(+good.article) : stopList.includes(+id);

      if (idMod) {
        const modified = getModifiedGood(good, idMod);
        return {
          ...modified,
          id: key,
          count,
          isStop: modified.isStop || isStopPoint,
          price: modified.isStop || isStopPoint ? 0 : modified.price,
        };
      }

      return {
        ...good,
        count,
        isStop: good.isStop || isStopPoint,
        price: good.isStop || isStopPoint ? 0 : good.price,
      };
    });
  },
);

export const denormalizedGoodsByIds = (ids) => createSelector(
  goodsEntitiesSelector,
  (entities) => {
    if (isEmpty(entities)) return [];
    return denormalizeItems(ids, 'goods', entities);
  },
);

export const recomendedGoodsSelector = createSelector(
  denormalizedGoods,
  cartListSelector,
  (goods, counts) => goods.filter((item) => item.recomended).map((item) => {
    if (item.isStop) return undefined;
    return { ...item, count: counts[item.id] };
  }).filter(Boolean),
);

export const giftGoodsSelector = createSelector(
  denormalizedGoods,
  selectedCitySelector,
  (goods) => goods.filter((item) => item.gift).map((item) => {
    if (item.isStop) return undefined;
    return { ...item, price: 0, count: 1 };
  }).filter(Boolean),
);

const getGoodsWok = (goods, categoryId) => goods
  .filter((item) => item.category.id === categoryId)
  .map((item) => ({
    id: item.id,
    article: item.article,
    title: item.name,
    price: item.price,
  }));

export const mainWokSelector = createSelector(
  denormalizedGoods,
  getCategorySettingsId('mainWok'),
  getGoodsWok,
);

export const souceWokSelector = createSelector(
  denormalizedGoods,
  getCategorySettingsId('souceWok'),
  getGoodsWok,
);

export const meatWokSelector = createSelector(
  denormalizedGoods,
  getCategorySettingsId('meatWok'),
  getGoodsWok,
);

export const toppingsWokSelector = createSelector(
  denormalizedGoods,
  getCategorySettingsId('toppingWok'),
  getGoodsWok,
);

export const totalGoodsCartSelector = createSelector(
  cartGoodsSelector,
  giftCartSelector,
  wokListCartSelector,
  (list, gift, woks) => {
    let result = list.reduce((acc, item) => acc + (item.count || 0), 0);

    if (!isEmpty(woks)) {
      result += Object.values(woks).reduce((acc, item) => acc + (item.count || 0), 0);
    }

    if (gift) {
      result += 1;
    }
    return result;
  },
);

export const countPositionCartSelector = createSelector(
  cartGoodsSelector,
  giftCartSelector,
  wokListCartSelector,
  (list, gift, woks) => {
    let result = list.reduce((acc) => acc + 1, 0);

    if (!isEmpty(woks)) {
      result += Object.values(woks).reduce((acc) => acc + 1, 0);
    }

    if (gift) {
      result += 1;
    }
    return result;
  },
);
