import dayjs from 'dayjs';
import React from 'react';
import { NavLink } from 'react-router-dom';

export function Footer() {
  return (
    <div style={{ borderTop: '1px solid #f0f2f5' }}>
      <div className="container-xxl footer pt-5 pb-5 d-flex flex-column flex-md-row flex-stack" id="kt_footer">
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-semibold me-1">
            {dayjs(new Date()).year()}
            ©
          </span>
          <span className="text-gray-800">SUSHIBOX</span>
        </div>
        <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
          <li className="menu-item">
            <NavLink to="/contacts" className="menu-link px-2">Контакты</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/policy" className="menu-link px-2">Политика конфиденциальности</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/franchise" className="menu-link ps-2 pe-0">Франшиза</NavLink>
          </li>
        </ul>

      </div>
    </div>
  );
}
