import {
  setOrderDeliveryAdress, setSelectedCity,
} from '../../../actions';
import { identityPayload, createReducer, forAction } from '../../../utils';

const initialState = {
  privateHouse: false,
  street: '',
  house: '',
  room: '',
  entrance: '',
  floor: '',
  area: '',
};

export default createReducer(
  [
    forAction(setOrderDeliveryAdress, identityPayload),
  ],
  initialState,
  [setSelectedCity],
);
