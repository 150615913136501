import { createAction } from 'redux-actions';
import { makeActionType } from '../utils';

export const initAction = createAction(makeActionType('APP', ['INIT']));
export const initSuccessAction = createAction(makeActionType('APP', ['INIT', 'SUCCEES']));

export const loaderAction = createAction(makeActionType('LAYOUT', ['SET', 'LOADER']));

export const setActiveModalAction = createAction(makeActionType('LAYOUT', ['SET', 'MODAL']));

export const setAside = createAction(makeActionType('LAYOUT', ['SET', 'ASIDE']));
