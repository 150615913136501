import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import settings from './settings';
import cities from './cities';
import loaders from './loaders';
import auth from './auth';
import modals from './modals';
import goods from './goods';
import cart from './cart';
import entities from './entities';
import order from './order';
import news from './news';
import notifications from './notifications';
import feedback from './feedback';
import { APP_CONFIG } from '../constants';
import aside from './aside';
import sliders from './sliders';

const cartKey = `${APP_CONFIG.name}_${APP_CONFIG.version}_cart`;

const cartPersistConfig = {
  key: cartKey,
  storage,
  whitelist: ['list', 'gift', 'wokList'],
};

export default combineReducers({
  auth,
  aside,
  cart: persistReducer(cartPersistConfig, cart),
  settings,
  cities,
  loaders,
  modals,
  goods,
  entities,
  order,
  news,
  notifications,
  feedback,
  sliders,
});
