/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderDeliveryMethod } from '../../../../actions';
import { Icon, IconTypes } from '../../../../components';
import { fromStore } from '../../../../selectors';

export function SelectDeliveryMethod() {
  const dispatch = useDispatch();
  const deliveryMethod = useSelector(fromStore.orderDeliveryMethodSelector);

  const onCheck = (e) => {
    dispatch(setOrderDeliveryMethod(e.target.value));
  };

  return (
    <div className="row">
      <div className="col-lg-6">
        <div>
          <input onChange={onCheck} checked={deliveryMethod === 'delivery'} id="delivery_method" type="radio" className="btn-check" value="delivery" />
          <label className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5" htmlFor="delivery_method">
            <span className="svg-icon svg-icon-3x me-5">
              <Icon type={IconTypes.delivery} />
            </span>
            <span className="d-block fw-bold text-start">
              <span className="text-dark fw-bolder d-block fs-4 mb-2">Доставка по адресу</span>
              <span className="text-muted fw-bold fs-6">Укажите адрес доставки</span>
            </span>
          </label>
        </div>
      </div>

      <div className="col-lg-6">
        <div>
          <input onChange={onCheck} checked={deliveryMethod === 'own'} type="radio" className="btn-check" value="own" id="own_method" />
          <label className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5" htmlFor="own_method">
            <span className="svg-icon svg-icon-3x me-5">
              <Icon type={IconTypes.shop} />
            </span>
            <span className="d-block fw-bold text-start">
              <span className="text-dark fw-bolder d-block fs-4 mb-2">Самовывоз</span>
              <span className="text-muted fw-bold fs-6">Выберите пункт самовывоза</span>
            </span>
          </label>
        </div>
      </div>
    </div>
  );
}
