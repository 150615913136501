import { cancelOrder, setOrderUnit } from '../../../actions';
import { identityPayload, createReducer, forAction } from '../../../utils';

const initialState = 0;

export default createReducer(
  [forAction(setOrderUnit, identityPayload)],
  initialState,
  [cancelOrder],
);
