import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { ProfileMenu } from './profile';
import { CartIcon, CityIcon, Logo } from '../components';
import { routesConfig } from '../constants';

export function Header() {
  const navBarItems = Object.keys(routesConfig)
    .filter((key) => !routesConfig[key].disableMenu).map((key) => (
      <NavLink key={key} className={({ isActive }) => cn('menu-item me-lg-1', { here: isActive })} to={routesConfig[key].href}>
        <span className="menu-link py-3">
          <span className="menu-title">{routesConfig[key].title}</span>
        </span>
      </NavLink>
    ));

  const headerMenu = (
    <div className="d-flex align-items-stretch">
      <div className="header-menu align-items-stretch">
        <div className="menu menu-lg-rounded menu-column menu-lg-row fw-bold my-5 my-lg-0 align-items-stretch">
          {navBarItems}
        </div>
      </div>
    </div>
  );

  return (
    <div className="d-flex header align-items-stretch">
      <div className="container-xxl d-flex align-items-stretch justify-content-between">
        <Logo />
        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
          {headerMenu}
          <div className="d-flex align-items-stretch flex-shrink-0">
            <CityIcon />
            <CartIcon />
            <ProfileMenu />
          </div>
        </div>
      </div>
    </div>
  );
}
