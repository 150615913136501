import { cancelOrder, setOrderUserName } from '../../../actions';
import { identityPayload, createReducer, forAction } from '../../../utils';

const initialState = '';

export default createReducer(
  [forAction(setOrderUserName, identityPayload)],
  initialState,
  [cancelOrder],
);
