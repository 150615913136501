import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { UserPageContent } from '../containers';
import { OrderDetailPage } from './orders/OrderDetailPage';
import { OrdersListPage } from './orders/OrdersListPage';

export default function OrdersPage() {
  return (
    <UserPageContent>
      <Routes>
        <Route path="/" element={<OrdersListPage />} />
        <Route path=":id" element={<OrderDetailPage />} />
      </Routes>
    </UserPageContent>
  );
}
