import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveModalAction, setSelectedCity } from '../../../actions';
import { fromStore } from '../../../selectors';
import { getCityGeoposition } from '../../../utils';

export function AutoCity({ onSetNotCityList }) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [isPromptAccess, setPrompt] = useState(false);
  const [autoCity, setAutoCity] = useState();
  const [closedForm, setClosedForm] = useState(false);
  const cities = useSelector(fromStore.filteredCitiesListSelector);
  const selected = useSelector(fromStore.selectedCitySelector);
  const updateUserLoader = useSelector(fromStore.cityLoaderSelector);

  const availableCity = autoCity ? cities.find(
    (item) => item.name.toLowerCase() === autoCity.toLowerCase(),
  ) : null;

  const onClose = () => dispatch(setActiveModalAction({ field: 'city', value: false }));

  const onSelectCity = (city) => () => {
    dispatch(setSelectedCity(city));
    onClose();
  };

  const fetchGeoposition = async () => {
    setLoader(true);
    if (navigator.permissions) {
      const perm = await navigator.permissions.query({
        name: 'geolocation',
      });
      if (perm.state === 'prompt') {
        setPrompt(true);
      }
    }
    const result = await getCityGeoposition();
    setAutoCity(result);
    if (!result) {
      onSetNotCityList(true);
    }
    setLoader(false);
    setPrompt(false);
  };

  const onSetClosedForm = () => {
    setClosedForm(true);
    if (isPromptAccess) {
      setPrompt(false);
    }
    onSetNotCityList(true);
  };

  useEffect(() => {
    if (!availableCity && autoCity) {
      onSetNotCityList(true);
    }
  }, [availableCity, autoCity]);

  useEffect(() => {
    if (!selected) fetchGeoposition();
  }, []);

  if (selected || closedForm) return null;

  const notFoundCity = (
    <div className="d-flex align-items-center">
      <span className="svg-icon svg-icon-1 me-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path opacity="0.3" d="M18.0624 15.3454L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3454C4.56242 13.6454 3.76242 11.4452 4.06242 8.94525C4.56242 5.34525 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24525 19.9624 9.94525C20.0624 12.0452 19.2624 13.9454 18.0624 15.3454ZM13.0624 10.0453C13.0624 9.44534 12.6624 9.04534 12.0624 9.04534C11.4624 9.04534 11.0624 9.44534 11.0624 10.0453V13.0453H13.0624V10.0453Z" fill="currentColor" />
          <path d="M12.6624 5.54531C12.2624 5.24531 11.7624 5.24531 11.4624 5.54531L8.06241 8.04531V12.0453C8.06241 12.6453 8.46241 13.0453 9.06241 13.0453H11.0624V10.0453C11.0624 9.44531 11.4624 9.04531 12.0624 9.04531C12.6624 9.04531 13.0624 9.44531 13.0624 10.0453V13.0453H15.0624C15.6624 13.0453 16.0624 12.6453 16.0624 12.0453V8.04531L12.6624 5.54531Z" fill="currentColor" />
        </svg>
      </span>
      Выберите город из списка
    </div>
  );

  const loaderContent = (
    <div className="d-flex align-items-center">
      <span className="spinner-border spinner-border-sm me-3 text-primary-sushibox" role="status" />
      Определяем ваш город...
    </div>
  );

  const renderButtonText = () => {
    if (loader) {
      return (
        <div>
          <div className="spinner-border text-light spinner-border-sm" role="status" />
        </div>
      );
    }

    return 'Да';
  };

  const mainContent = (
    <>
      <div className="d-flex align-items-center">
        <span className="svg-icon svg-icon-1 me-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path opacity="0.3" d="M18.0624 15.3454L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3454C4.56242 13.6454 3.76242 11.4452 4.06242 8.94525C4.56242 5.34525 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24525 19.9624 9.94525C20.0624 12.0452 19.2624 13.9454 18.0624 15.3454ZM13.0624 10.0453C13.0624 9.44534 12.6624 9.04534 12.0624 9.04534C11.4624 9.04534 11.0624 9.44534 11.0624 10.0453V13.0453H13.0624V10.0453Z" fill="currentColor" />
            <path d="M12.6624 5.54531C12.2624 5.24531 11.7624 5.24531 11.4624 5.54531L8.06241 8.04531V12.0453C8.06241 12.6453 8.46241 13.0453 9.06241 13.0453H11.0624V10.0453C11.0624 9.44531 11.4624 9.04531 12.0624 9.04531C12.6624 9.04531 13.0624 9.44531 13.0624 10.0453V13.0453H15.0624C15.6624 13.0453 16.0624 12.6453 16.0624 12.0453V8.04531L12.6624 5.54531Z" fill="currentColor" />
          </svg>
        </span>
        {`Ваш город: ${autoCity}`}
      </div>
      {availableCity ? (
        <div className="d-flex align-items-center">
          <button disabled={updateUserLoader} onClick={onSetClosedForm} type="button" className="btn w-100 btn-light">
            Нет
          </button>
          <button disabled={updateUserLoader} onClick={onSelectCity(availableCity)} type="button" className="btn d-flex justify-content-center w-100 btn-danger ms-3">
            {renderButtonText()}
          </button>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <span className="text-danger">Не работаем в этом городе</span>
        </div>
      )}
    </>
  );

  const content = () => {
    if (loader) return loaderContent;
    if (!autoCity) return notFoundCity;

    return mainContent;
  };

  return (
    <>
      <div className="d-flex flex-stack border border-gray-300 border-dashed rounded py-3 px-3 mb-5">
        {content()}
      </div>
      {isPromptAccess && (
      <div className="text-center">
        <div className="fw-bold">
          Дайте разрешение на использование данных о вашем местопложении или выберите город вручную
        </div>
        <button disabled={updateUserLoader} onClick={onSetClosedForm} type="button" className="btn mt-7 w-100 btn-primary">
          Выбрать город
        </button>
      </div>
      )}
    </>
  );
}

AutoCity.propTypes = {
  onSetNotCityList: PropTypes.func.isRequired,
};
