import React from 'react';
import { useSelector } from 'react-redux';
import { Card } from './card';
import { Loader } from '../components';
import { fromStore } from '../selectors';

export function FavoriteList() {
  const user = useSelector(fromStore.userSelector);
  const loader = useSelector(fromStore.goodsLoaderSelector);
  const ids = user.favorites || [];

  if (loader) return <Loader list />;

  const emptyResult = (
    <div className="fw-bold">
      <div className="text-gray-700 fs-3 mb-2">Вы ничего не добавили в избранное</div>
    </div>
  );

  return (

    <div className="post abs">
      <div className="row g-5 g-xl-8">
        {ids.length ? ids.map((id) => <Card key={id} id={String(id)} />) : emptyResult}
      </div>
    </div>

  );
}
