import React from 'react';
import { FranchiseBanner, Sidebar } from '../components';
import { routesConfig } from '../constants';
import { ContactsList } from '../containers';

export default function ContactsPage() {
  const pages = Object.keys(routesConfig)
    .filter((key) => routesConfig[key].group === 1)
    .map((key) => routesConfig[key]);

  return (
    <>
      <Sidebar pages={pages} banner={<FranchiseBanner />} />
      <div className="wrapper d-flex flex-column flex-row-fluid mt-5 mt-lg-10">
        <div className="content flex-column-fluid position-relative">
          <div className="toolbar d-flex flex-stack flex-wrap mb-5 mb-lg-7">
            <div className="page-title d-flex flex-column py-1">
              <h1 className="d-flex align-items-center my-1">
                <span className="text-dark fw-bolder fs-1">Доставка и контакты</span>
              </h1>
            </div>
          </div>
          <ContactsList />
        </div>
      </div>
    </>
  );
}
