/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderMessage } from '../../../../actions';
import { fromStore } from '../../../../selectors';

export function Description() {
  const dispatch = useDispatch();
  const value = useSelector(fromStore.orderMessageSelector);
  const createLoader = useSelector(fromStore.createOrderLoaderSelector);

  const onChange = (e) => {
    dispatch(setOrderMessage(e.target.value));
  };

  return (
    <div className="mb-10">
      <label className="form-label">Примечания</label>
      <textarea
        disabled={createLoader}
        maxLength={250}
        onChange={onChange}
        value={value}
        placeholder="Введите текст примечания..."
        className="form-control form-control-lg form-control-solid"
        rows="3"
      />
    </div>
  );
}
