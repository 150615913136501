import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchSliders, loaderAction, setSliders } from '../actions';
import { restUrls } from '../constants';
import { request } from '../utils';

function* fetchSlidersHandler() {
  yield put(loaderAction({ field: 'sliders', value: true }));
  const sliders = yield call(request, { method: 'get', url: `${restUrls.sliders}`, params: { _limit: -1, _sort: 'priority:DESC', active: true } }, false);
  yield put(setSliders(sliders));
  yield put(loaderAction({ field: 'sliders', value: false }));
}

export default [
  takeLatest(fetchSliders, fetchSlidersHandler),
];
