import { setActiveModalAction, setUser } from '../actions';
import { createReducer, forAction } from '../utils';

const initialState = {
  auth: false,
  city: false,
  detail: null,
  adress: false,
  point: false,
  wok: false,
};

export default createReducer(
  [forAction(setActiveModalAction, (state, { payload: { field, value } }) => ({
    ...state,
    [field]: value,
  }))],
  initialState,
  [setUser],
);
