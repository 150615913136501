import React from 'react';
import { Link } from 'react-router-dom';

export default function Page404() {
  return (
    <div className="d-flex flex-column flex-center flex-column-fluid p-10">
      <div className="fw-bolder" style={{ fontSize: '100px' }}>404</div>
      <h1 className="fw-bold mb-10" style={{ color: '#A3A3C7' }}>Страница не найдена</h1>
      <Link to="/" className="btn btn-primary">На главную</Link>
    </div>
  );
}
