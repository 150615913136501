import { combineReducers } from 'redux';
import adress from './adress';
import method from './method';
import point from './point';
import zone from './zone';
import zoneData from './zoneData';
import timeMethod from './timeMethod';
import deliveryTime from './deliveryTime';

export default combineReducers({
  adress,
  method,
  point,
  zone,
  zoneData,
  timeMethod,
  deliveryTime,
});
