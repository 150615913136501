import React from 'react';
import { useDispatch } from 'react-redux';
import { setActiveModalAction } from '../actions';

export function NonAuth() {
  const dispatch = useDispatch();

  const openAuth = () => {
    dispatch(setActiveModalAction({ field: 'auth', value: true }));
  };

  return (
    <div className="post flex-column-fluid mt-10">

      <div className="row g-7">

        <div className="col-xl-12">

          <div className="card card-flush h-lg-100" id="kt_contacts_main">

            <div className="card-body p-0">

              <div className="card-px text-center py-20 my-10">

                <h2 className="fs-2x fw-bolder mb-10">Доступ запрещен</h2>

                <p className="text-gray-400 fs-4 fw-bold mb-10">
                  Для доступа к этой странице войдите в систему
                </p>

                <button type="button" onClick={openAuth} className="btn btn-primary">Войти</button>

              </div>

              <div className="text-center px-4 mb-20">
                <img className="mw-100 mh-300px rounded" alt="" src="/assets/media/denied.jpg" />
              </div>

            </div>

          </div>

        </div>

      </div>

    </div>
  );
}
