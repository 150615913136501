import React from 'react';
import PropTypes from 'prop-types';
import { numberDevider } from '../../../utils';

export function GoodsPrice({ order }) {
  return (
    <>
      <tr>
        <td colSpan="3" className="text-end">Сумма заказа</td>
        <td className="text-end">{`${numberDevider(order.goodsPrice)} ₸`}</td>
      </tr>

      {order.bonusCount > 0 && (
        <tr>
          <td colSpan="3" className="text-end">Оплачено бонусами</td>
          <td className="text-end">{`- ${order.bonusCount} ₸`}</td>
        </tr>
      )}

      {order.salePrice > 0 && (
        <tr>
          <td colSpan="3" className="text-end">{order.sale ? order.sale.name : 'Скидка'}</td>
          <td className="text-end">{`- ${order.salePrice} ₸`}</td>
        </tr>
      )}

      <tr>
        <td colSpan="3" className="text-end">Доставка</td>
        <td className="text-end">{`${order.deliveryPrice} ₸`}</td>
      </tr>

      <tr>
        <td colSpan="3" className="fs-3 text-dark text-end">К оплате</td>
        <td className="text-dark fs-3 fw-boldest text-end">{`${numberDevider(order.totalPrice)} ₸`}</td>
      </tr>
    </>
  );
}

GoodsPrice.propTypes = {
  order: PropTypes.object.isRequired,
};
