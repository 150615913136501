/* eslint-disable jsx-a11y/label-has-associated-control */
import dayjs from 'dayjs';
import React from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderDeliveryTimeMethod } from '../../../../actions';
import { fromStore } from '../../../../selectors';
import { Icon, IconTypes } from '../../../../components';
import { DeliveryTime } from './DeliveryTime';

export function SelectTimeMethod() {
  const dispatch = useDispatch();
  const deliveryMethod = useSelector(fromStore.orderDeliveryMethodSelector);
  const point = useSelector(fromStore.orderDeliveryPointSelector);
  const timeMethod = useSelector(fromStore.orderDeliveryTimeMethodSelector);
  const zoneData = useSelector(fromStore.orderDeliveryZoneDataSelector);

  const getHandleTime = () => {
    if (deliveryMethod === 'own') {
      return (point && point.minSamTime) || 30;
    }

    if (zoneData) {
      const minTime = (zoneData.point && zoneData.point.minDeliveryTime) || 60;
      const addTime = zoneData.deliveryTime || 0;
      return +minTime + +addTime;
    }

    return 60;
  };

  const handleTime = getHandleTime();

  const defaultValue = dayjs(new Date());
  const minDate = dayjs(defaultValue).add(handleTime, 'minutes').toString();
  const maxDate = dayjs(defaultValue).add(7, 'days').toString();

  const onChange = (e) => {
    dispatch(setOrderDeliveryTimeMethod(e.target.value));
  };

  return (
    <div className="mt-15 mb-10">
      <h3 className="mb-7 required">Дата и время</h3>
      <div className="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">

        <label className="d-flex flex-stack mb-3 cursor-pointer">
          <span className="d-flex align-items-center me-2">
            <span className="symbol symbol-40px me-6">
              <span className="symbol-label bg-gray-200">
                <span className={cn('svg-icon svg-icon-1', timeMethod === 'fast' ? 'svg-icon-primary' : 'svg-icon-gray-600')}>
                  <Icon type={IconTypes.rocket} />
                </span>
              </span>
            </span>
            <span className="d-flex flex-column">
              <span className="fw-bold text-gray-800 fs-5">Как можно скорее</span>
            </span>
          </span>
          <span className="form-check form-check-custom form-check-solid">
            <input onChange={onChange} className="form-check-input" type="radio" value="fast" checked={timeMethod === 'fast'} />
          </span>
        </label>

        <label className="d-flex flex-stack mb-3 cursor-pointer">
          <span className="d-flex align-items-center me-2">
            <span className="symbol symbol-40px me-6">
              <span className="symbol-label bg-gray-200">
                <span className={cn('svg-icon svg-icon-1', timeMethod === 'time' ? 'svg-icon-primary' : 'svg-icon-gray-600')}>
                  <Icon type={IconTypes.time} />
                </span>
              </span>
            </span>
            <span className="d-flex flex-column">
              <span className="fw-bold text-gray-800 fs-5">К определенному времени</span>
            </span>
          </span>
          <span className="form-check form-check-custom form-check-solid">
            <input onChange={onChange} className="form-check-input" type="radio" name="category" value="time" checked={timeMethod === 'time'} />
          </span>
        </label>

        {timeMethod === 'time' && (
        <div className="d-flex flex-stack mb-3 cursor-pointer">
          <span className="d-flex align-items-center me-2">
            <span className="symbol symbol-40px me-6">
              <span className="symbol-label bg-white">
                <Icon className={cn('svg-icon svg-icon-1', timeMethod === 'time' ? 'svg-icon-primary' : 'svg-icon-gray-600')} type={IconTypes.arrowRight} />
              </span>
            </span>
            <span className="d-flex flex-column">
              <DeliveryTime minDate={minDate} maxDate={maxDate} />
            </span>
          </span>
        </div>
        )}
      </div>
    </div>
  );
}
