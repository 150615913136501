import { addGiftToCart, clearCart, deleteGiftFromCart } from '../../actions';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = null;

export default createReducer(
  [forAction(addGiftToCart, identityPayload)],
  initialState,
  [clearCart, deleteGiftFromCart],
);
