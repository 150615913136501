import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { fromStore } from '../../selectors';
import { closeCart } from '../../actions';
import { CartHeader } from './CartHeader';
import { CartBody } from './CartBody';
import { CartFooter } from './CartFooter';
import { useOnClickOutside } from '../../hooks';

export function CartBar() {
  const dispatch = useDispatch();
  const ref = useRef();
  const active = useSelector(fromStore.activeCartSelector);
  const totalGoods = useSelector(fromStore.totalGoodsCartSelector);
  const canClose = useSelector(fromStore.canCloseCartSelector);

  const onClose = () => dispatch(closeCart());

  useOnClickOutside(ref, () => active && canClose && onClose());

  useEffect(() => {
    if (totalGoods === 0) onClose();
  }, [totalGoods]);

  return (
    <>
      <div ref={ref} className={cn('bg-body drawer drawer-end ', { 'drawer-on': active })}>
        <div className="card rounded-0 border-0 w-100">
          <CartHeader />
          <CartBody />
          <CartFooter />
        </div>
      </div>
      {active && <div style={{ zIndex: 109 }} className="drawer-overlay" />}
    </>
  );
}
