import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NonAuth, Sidebar } from '../components';
import { routesConfig } from '../constants';
import { fromStore } from '../selectors';

export function UserPageContent({ title, children }) {
  const user = useSelector(fromStore.userSelector);

  if (!user) return <NonAuth />;

  const pages = Object.keys(routesConfig)
    .filter((key) => routesConfig[key].group === 2)
    .map((key) => {
      let count;
      switch (key) {
        case 'favorites': count = user.favorites ? user.favorites.length : 0; break;
        case 'orders': count = user.orders.length; break;
        default: count = undefined; break;
      }
      return { ...routesConfig[key], count };
    });

  const content = (
    <div className="wrapper d-flex flex-column flex-row-fluid mt-5 mt-lg-10">
      <div className="content position-relative flex-column-fluid">
        {title && (
        <div className="toolbar d-flex flex-stack flex-wrap pb-5 pb-lg-5 border-bottom">
          <div className="page-title d-flex flex-column py-1">
            <h1 className="d-flex align-items-center my-1">
              <span className="text-dark fw-bolder fs-1">{title}</span>
            </h1>
          </div>
        </div>
        )}
        {children}
      </div>
    </div>
  );

  return (
    <>
      <Sidebar pages={pages} />
      {content}
    </>
  );
}

UserPageContent.defaultProps = {
  title: '',
};

UserPageContent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element.isRequired,
};
