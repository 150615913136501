import React from 'react';
import { FranchiseBanner, Sidebar } from '../components';
import { routesConfig } from '../constants';
import { FeedbackForm } from '../containers/FeedbackForm';

export default function ContactsPage() {
  const pages = Object.keys(routesConfig)
    .filter((key) => routesConfig[key].group === 1)
    .map((key) => routesConfig[key]);

  return (
    <>
      <Sidebar pages={pages} banner={<FranchiseBanner />} />
      <div className="wrapper d-flex flex-column flex-row-fluid mt-5 mt-lg-10">
        <div className="content flex-column-fluid position-relative">
          <div className="toolbar d-flex flex-stack flex-wrap mb-5 mb-lg-7">
            <div className="page-title d-flex flex-column py-1">
              <h1 className="d-flex align-items-center my-1">
                <span className="text-dark fw-bolder fs-1">Удаление аккаунта SUSHIBOX</span>
              </h1>
              <div className="fs-3 mt-10">
                Чтобы удалить учетную запись в приложении
                {' '}
                <span className="fw-bold ">SUSHI BOX - доставка роллов нужно</span>
                :
              </div>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="flex-lg-row-auto w-100">
              <div className="card bg-light">
                <div className="card-body">
                  <div className="">
                    <div className="my-2">
                      <div className="d-flex align-items-center mb-3">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">Зайти в аккаунт SUSHI BOX - доставка роллов и нажать на кнопку удалить аккаунт</div>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">Оставить запрос в форме обратной связи</div>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">Указать данные вашей учетной записи (Имя, телефон)</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="bullet me-3" />
                        <div className="text-gray-600 fw-bold fs-4">После обработки запроса, мы удалим учетную запись</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FeedbackForm />
        </div>
      </div>
    </>
  );
}
