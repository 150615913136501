/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import dayjs from 'dayjs';
import { Icon, IconTypes, Loader } from '../../components';
import { fromStore, newsSelector } from '../../selectors';
import { fetchNews } from '../../actions';
import { DATE_FORMAT } from '../../constants';
import { getImageUrl } from '../../utils';

export function NewsDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loader = useSelector(fromStore.newsLoaderSelector);
  const news = useSelector(newsSelector);

  useEffect(() => {
    if (news.length === 0) {
      dispatch(fetchNews());
    }
  }, []);

  const goBack = () => {
    navigate('/blog');
  };

  if (loader) return <Loader list />;

  const item = news.find((itemNews) => itemNews.id === +id);

  if (!item) {
    return (
      <div className="fw-bold">
        <div className="text-gray-700 fs-3 mb-2">Такой записи нет</div>
      </div>
    );
  }

  return (
    <div className="wrapper d-flex flex-column flex-row-fluid mt-5 mt-lg-10">
      <div className="content flex-column-fluid" id="kt_content">
        <div className="toolbar d-flex flex-stack flex-wrap mb-5 mb-lg-7">
          <div className="page-title d-flex flex-column py-1">
            <h1 className="d-flex align-items-center my-1">
              <span className="text-dark fw-bolder fs-1">{item.title}</span>
            </h1>
          </div>
          <div onClick={goBack} aria-hidden className="btn btn-light btn-sm btn-hover-scale">
            <Icon className="svg-icon svg-icon-muted svg-icon-1" type={IconTypes.back} />
            Назад
          </div>
        </div>
        <div className="post">
          <div className="card">
            <div className="card-body p-10">
              <div className="d-flex flex-column flex-xl-row">
                <div className="flex-lg-row-fluid me-xl-15">
                  <div className="mb-0">

                    <div className="d-flex mb-8 align-items-center">
                      <div className="w-100px border rounded me-5">
                        <img className="w-100 rounded" src={getImageUrl(item.image)} alt={item.title} />
                      </div>
                      <div>
                        <div className="d-flex flex-wrap mb-4">
                          <div className="d-flex me-9 my-1 align-items-center">
                            <Icon className="svg-icon svg-icon-gray-700 svg-icon-3 me-2" type={IconTypes.calendar} />
                            <span className="fw-bolder text-gray-400">{dayjs(item.created_at).format(DATE_FORMAT)}</span>
                          </div>
                        </div>
                        <div className="text-dark fs-2 fw-bolder">
                          {item.description}
                        </div>
                      </div>
                    </div>
                    <div className="fs-5 text-gray-800">
                      <ReactMarkdown>
                        {item.text}
                      </ReactMarkdown>
                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  );
}
