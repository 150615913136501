import { cancelOrder, setOrderPhone } from '../../../actions';
import { identityPayload, createReducer, forAction } from '../../../utils';

const initialState = '';

export default createReducer(
  [forAction(setOrderPhone, identityPayload)],
  initialState,
  [cancelOrder],
);
