import React from 'react';
import PropTypes from 'prop-types';

export function Checkbox({
  title, checked, onChange, disabled, id,
}) {
  const onChangeValue = (e) => {
    onChange(e.target.checked);
  };

  return (
    <div className="form-check form-check-custom form-check-solid">
      <input
        id={id}
        checked={checked}
        onChange={onChangeValue}
        className="form-check-input"
        disabled={disabled}
        type="checkbox"
      />
      <label className="form-check-label fs-6 fw-bold ms-3" htmlFor={id}>
        {title}
      </label>
    </div>
  );
}

Checkbox.defaultProps = {
  disabled: false,
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};
