import React from 'react';
import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';

export function Sidebar({ pages, banner }) {
  return (
    <div className="aside">
      <div className="hover-scroll-overlay-y my-5 my-lg-5 w-100 ps-4 ps-lg-0 pe-4 me-1">
        <div className="menu menu-column menu-active-bg menu-hover-bg menu-title-gray-700 fs-6 menu-rounded w-100">
          {pages.map((page) => (
            <div key={page.href} className="menu-item">
              <NavLink to={page.href} className="menu-link">
                <span className="menu-title">{page.title}</span>
                {page.count !== undefined && (
                  <span className="badge badge-secondary fw-bolder fs-7">{page.count}</span>
                )}
              </NavLink>
            </div>
          ))}
        </div>
        {banner}
      </div>
    </div>
  );
}

Sidebar.defaultProps = {
  banner: null,
};

Sidebar.propTypes = {
  pages: PropTypes.array.isRequired,
  banner: PropTypes.node,
};
