import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { fromStore } from '../../../../selectors';
import { setOrderDeliveryTime } from '../../../../actions';
import { DateTimeInput } from '../../../../components';

export function DeliveryTime({ minDate, maxDate }) {
  const dispatch = useDispatch();
  const deliveryTime = useSelector(fromStore.orderDeliveryTimeSelector);

  useEffect(() => {
    dispatch(setOrderDeliveryTime(dayjs(minDate).toISOString()));
  }, []);

  const onChangeDate = (date) => {
    dispatch(setOrderDeliveryTime(dayjs(date).toISOString()));
  };

  const value = dayjs(deliveryTime).toString();

  return (
    <DateTimeInput
      minDate={minDate}
      maxDate={maxDate}
      value={value}
      onChange={onChangeDate}
    />
  );
}

DeliveryTime.propTypes = {
  minDate: PropTypes.string.isRequired,
  maxDate: PropTypes.string.isRequired,
};
