import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, IconTypes } from '../../../../components';
import { fromStore } from '../../../../selectors';

export function DeliveryPoint() {
  const point = useSelector(fromStore.orderDeliveryPointSelector);
  if (!point) return null;

  const renderPointTimeInfo = () => (
    <div className="d-flex flex-stack mb-0">
      <span className="d-flex align-items-center me-2">
        <span className="symbol symbol-40px me-6">
          <span className="symbol-label bg-light-success">
            <span className="svg-icon svg-icon-1 svg-icon-success">
              <Icon type={IconTypes.clock} />
            </span>
          </span>
        </span>
        <span className="d-flex flex-column">
          <span className="fw-bold text-gray-800 fs-5">Время готовности</span>
        </span>
      </span>
      <span className="form-check form-check-custom form-check-solid">
        {`через ${point.minSamTime} мин после подтверждения заказа`}
      </span>
    </div>
  );

  const renderStopPoint = () => {
    if (point.activate === 'active' || point.activate === 'disabledDelivery') return null;

    return (
      <div className="alert alert-danger d-flex align-items-center p-5 mt-10">
        <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
          <Icon type={IconTypes.error} />
        </span>
        <div className="d-flex flex-column">
          <span>По техническим причинам пункт самовывоза не работает</span>
        </div>
      </div>
    );
  };

  return (
    <div className="mb-0 mt-7">
      {point.minSamTime && renderPointTimeInfo()}
      {(point.activate === 'disabledAll' || point.activate === 'disabledOwn') && renderStopPoint()}
    </div>
  );
}
