const packageJson = require('../../package.json');

export const EMAIL_REG_EXP = /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@([a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|[a-z][a-z])$/i;
export const PHONE_REG_EXP = /^\+?[78]\s[-(]?\d{3}\)?\s?\d{3}-?\d{2}-?\d{2}$/;

export const DATE_FORMAT = 'D MMMM YYYY в HH:mm';
export const DATE_FORMAT_SHORT = 'D MMMM YYYY';

export const SHORT_DASH = '-';
export const LONG_DASH = '—';
export const EMPTY_TEXT = '';

export const GEO_CONFIG = {
  url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address',
  token: 'e05a790a4de989b5bf6a09b65ec8f708cfff7b5d',
};

export const APP_CONFIG = {
  name: packageJson.name,
  version: packageJson.version,
};

export const ORDER_VALUE_MAPS = {
  status: {
    cancel: { style: 'badge-danger', title: 'Отменён' },
    done: { style: 'badge-success', title: 'Выполнен' },
    work: { style: 'badge-info', title: 'В работе' },
    new: { style: 'badge-primary', title: 'Новый' },
    return: { style: 'badge-danger', title: 'Возврат' },
  },
  deliveryMethod: {
    own: 'Самовывоз',
    delivery: 'Доставка',
  },
  payment: {
    terminal: 'Терминал (картой при получении)',
    cash: 'Наличные',
    online: 'Онлайн-оплата',
  },
  changeCash: {
    no: 'под расчет',
    c1000: '2 000 ₸',
    c1500: '5 000 ₸',
    c2000: '10 000 ₸',
    c5000: '20 000 ₸',
  },
};

export const errorMessages = {
  required: 'Обязательное поле',
};

export const adressFields = {
  privateHouse: {
    title: 'Частный дом',
    defaultValue: false,
  },
  street: {
    title: 'Улица',
    defaultValue: EMPTY_TEXT,
    validation: {
      required: { value: true, message: errorMessages.required },
      maxLength: 50,
      minLength: 3,
    },
  },
  house: {
    title: 'Дом',
    defaultValue: EMPTY_TEXT,
    validation: {
      required: { value: true, message: errorMessages.required },
      maxLength: 8,
      minLength: 1,
    },
  },
  room: {
    title: 'Квартира',
    defaultValue: EMPTY_TEXT,
    validation: {
      required: { value: true, message: errorMessages.required },
      maxLength: 5,
      minLength: 1,
    },
    disableValidationFormPrivate: true,
  },
  entrance: {
    title: 'Подъезд',
    defaultValue: EMPTY_TEXT,
    validation: {
      required: { value: true, message: errorMessages.required },
      maxLength: 3,
      minLength: 1,
    },
    disableValidationFormPrivate: true,
  },
  floor: {
    title: 'Этаж',
    defaultValue: EMPTY_TEXT,
    validation: {
      required: { value: true, message: errorMessages.required },
      maxLength: 3,
      minLength: 1,
    },
    disableValidationFormPrivate: true,
  },
  area: {
    title: 'Район',
    defaultValue: EMPTY_TEXT,
    validation: {
      maxLength: 50,
      minLength: 1,
    },
  },
};

export const feedbackFields = {
  name: {
    title: 'Имя',
    defaultValue: EMPTY_TEXT,
    validation: {
      required: { value: true, message: errorMessages.required },
      maxLength: 50,
      minLength: 3,
    },
  },
  email: {
    title: 'E-mail',
    defaultValue: EMPTY_TEXT,
    validation: {
      required: { value: true, message: errorMessages.required },
      maxLength: 50,
      minLength: 1,
      pattern: {
        value: EMAIL_REG_EXP,
        message: 'Введен некорректный E-mail',
      },
    },
  },
  message: {
    title: 'Сообщение',
    defaultValue: EMPTY_TEXT,
    validation: {
      required: { value: true, message: errorMessages.required },
      maxLength: 400,
      minLength: 10,
    },
  },
};

export const LEXICON = {
  confirmTextOrder: 'С вами свяжется менеджер для подтверждения заказа',
  inWorkTextOrder: 'Начинаем готовить ваш заказ',
  notPay: 'Оплатите заказ и мы начнем его готовить',
};

export const MEASURE = {
  gramm: { title: 'Вес', value: 'гр.' },
  liter: { title: 'Объем', value: 'л.' },
  pc: { title: 'Кол-во', value: 'шт.' },
};
